import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Spinner, Alert, Button, Card, CardBody, Collapse, CardText,
  Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label
} from 'reactstrap';
import Header from 'components/Headers/Header.js'; // Ajusta la ruta de importación según sea necesario
import background from 'assets/img/brand/fondoprincipal.png'; // Ajusta la ruta según sea necesario
import '../../assets/css/DetailsVentaCruzada.css'; // Asegúrate de que la ruta es correcta
import axios from 'axios';
import config from 'config/config';
import { FaChevronDown, FaChevronUp, FaShoppingCart } from 'react-icons/fa'; // Importa los íconos
import { PageContentContext } from 'context/PageContentContext';
import ReactMarkdown from 'react-markdown';
import TypewriterText from '../../components/Typewriter/TypewriterText';

const DetailsVentaCruzada = () => {
  const { id } = useParams(); // Extrae 'id' de la URL
  const navigate = useNavigate(); // Hook para redirección
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPhoneNumbersOpen, setIsPhoneNumbersOpen] = useState(false);
  const [isClientEmailsOpen, setIsClientEmailsOpen] = useState(false);
  const [isCobranzaEmailsOpen, setIsCobranzaEmailsOpen] = useState(false);
  const [isHistorialVentasOpen, setIsHistorialVentasOpen] = useState(false);
  const { setPageContent } = useContext(PageContentContext);
  const token = localStorage.getItem('naseda_token');

  // Estados para el modal de Venta Cruzada
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ventaCruzadaInput, setVentaCruzadaInput] = useState('');
  const [processLoading, setProcessLoading] = useState(false);
  const [modalError, setModalError] = useState(null);

  //Modal para generar descripción
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [generateMode, setGenerateMode] = useState('url'); // 'url' o 'manual'
  const [clientURL, setClientURL] = useState('');
  const [manualDescription, setManualDescription] = useState('');
  const [generateLoading, setGenerateLoading] = useState(false);
  const [generateError, setGenerateError] = useState(null);
  const [generateSuccess, setGenerateSuccess] = useState(null);
  const [detailsFirstTime, setDetailsFirstTime] = useState(true);

  const handleGenerateDescription = async () => {
    setGenerateLoading(true);
    setGenerateError(null);
    setGenerateSuccess(null);

    // Validamos según el modo seleccionado
    if (generateMode === 'url') {
      if (!clientURL.trim()) {
        setGenerateError('Por favor, ingrese una URL para continuar.');
        setGenerateLoading(false);
        return;
      }
    } else {
      if (!manualDescription.trim()) {
        setGenerateError('Por favor, ingrese la descripción manual.');
        setGenerateLoading(false);
        return;
      }
    }

    try {
      // Estructura que se enviará al backend
      const payload = {
        id,
        url: generateMode === 'url' ? clientURL : '',
        description: generateMode === 'manual' ? manualDescription : '',
      };
      const response = await axios.post(
        `${config.backendURL}/generate_client_description`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Éxito
      setGenerateSuccess('Descripción actualizada exitosamente. La página se recargará automáticamente.');

      // Recargamos la página después de 3 segundos
      const timer = setTimeout(() => {
        setGenerateSuccess(null);
        setIsGenerateModalOpen(false);
        setClientURL('');
        setManualDescription('');
        window.location.reload(); // Reload the page
      }, 3000);

      return () => clearTimeout(timer);
    } catch (err) {
      console.error('Error generating client description:', err);
      setGenerateError('Error al generar la descripción. Por favor, intenta de nuevo.');
    } finally {
      setGenerateLoading(false);
    }
  };

  useEffect(() => {
    // Fetch client data using Axios
    const fetchClient = async () => {
      try {
        const response = await axios.get(`${config.backendURL}/clients/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClient(response.data.client); // Asumiendo que los datos del cliente están en response.data.client
      } catch (err) {
        console.error('Error fetching client data:', err);
        setError('Error fetching client data.');
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [id, token]);

  useEffect(() => {
    if (generateError) {
      const timer = setTimeout(() => {
        setGenerateError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [generateError]);

  useEffect(() => {
    if (client) {
      // Convertir los datos del cliente a una cadena JSON para enviar al backend
      const pageContentObject = {
        purpose: 'Esta es la ventana de detalles de un cliente seleccionado. En esta se puede consultar la información del cliente',
        clientData: client
      };

      // Convert the pageContentObject to a JSON string
      const content = JSON.stringify(pageContentObject, null, 2);

      setPageContent(content);
    }
  }, [client, setPageContent]);

  const handleIniciarVentaCruzada = async () => {
    setProcessLoading(true);
    setModalError(null); 
    // Sólo por si tu backend necesita un retardo o deseas simularlo
    await new Promise(resolve => setTimeout(resolve, 5000));
    try {
      const response = await axios.get(`${config.backendURL}/cross_sell`, {
        params: {
          id: id,
          input: ventaCruzadaInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const crossSellResults = response.data.cross_sell_items;

      navigate(`/admin/mis-clientes/${id}/resultados-de-venta-cruzada`, {
        state: { crossSellResults, client },
      });
    } catch (err) {
      console.error('Error processing venta cruzada:', err);
      setModalError('Ocurrió un error. Inténtlo más tarde.');
    } finally {
      setProcessLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container className="d-flex justify-content-center align-items-center flex-grow-1">
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container className="d-flex justify-content-center align-items-center flex-grow-1">
          <Alert color="danger">{error}</Alert>
        </Container>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Container className="flex-grow-1 ">
        {client ? (
          <div className="details-container">
            <div className="row">
              {/* Left Column */}
              <div className="col-md-8">
                <div className="left-column">
                  {/* Card Component */}
                  <Card className="mb-4">
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <CardText className='mb--2 font-italic'>{client.sn_code}</CardText>
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: client.details.active ? 'green' : 'red',
                            borderRadius: 10,
                            paddingInline: 10
                          }}
                        >
                          {client.details.active ? 'Activo' : 'Inactivo'}
                        </span>
                      </div>
                      <CardText>
                        <h1>{client.business_name}</h1>
                      </CardText>
                      <Button color="primary" onClick={() => setIsGenerateModalOpen(true)}>
                        Generar
                      </Button>
                      <CardText className="mt-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <strong>Descripción:</strong>
                        {/* Generado por NASEDA Copilot */}
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <i className="bi bi-stars" style={{ marginRight: '5px' }}></i>
                          Generado por NASEDA Copilot
                        </span>
                      </CardText>
                      {/* Descripción del cliente */}
                      <TypewriterText
                        text={client.details.description}
                        trigger={true}
                        firstTime={detailsFirstTime}
                        setFirstTime={setDetailsFirstTime}
                        speedMin={10}
                        speedMax={20}
                        delay={0}
                      />
                    </CardBody>
                  </Card>

                  {/* Display nested details */}
                  {client.details && (
                    <>
                      {/* Contact Details */}
                      {client.details.contact_details && (
                        <>
                          <Card className="mb-4">
                            <CardBody>
                              <h3 className="mt-3">Detalles de Contacto</h3>
                              <p>
                                <strong>Nombre de la persona contacto:</strong>{' '}
                                {client.details.contact_details.contact_name}
                              </p>
                              {/* Phone Numbers */}
                              {client.details.contact_details.phone_numbers &&
                                client.details.contact_details.phone_numbers.length > 0 && (
                                  <>
                                    <p>
                                      <strong
                                        onClick={() => setIsPhoneNumbersOpen(!isPhoneNumbersOpen)}
                                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                      >
                                        Números de Teléfono:
                                        <span style={{ marginLeft: '8px' }}>
                                          {isPhoneNumbersOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                      </strong>
                                    </p>
                                    <Collapse isOpen={isPhoneNumbersOpen}>
                                      <ul>
                                        {client.details.contact_details.phone_numbers.map(
                                          (phone, index) => (
                                            <li key={index}>
                                              {phone.number}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </Collapse>
                                  </>
                                )}
                              {/* Emails */}
                              {client.details.contact_details.emails &&
                                client.details.contact_details.emails.length > 0 && (
                                  <>
                                    <p>
                                      <strong
                                        onClick={() => setIsClientEmailsOpen(!isClientEmailsOpen)}
                                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                      >
                                        Correos Electrónicos de cliente
                                        <span style={{ marginLeft: '8px' }}>
                                          {isClientEmailsOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                      </strong>
                                    </p>
                                    <Collapse isOpen={isClientEmailsOpen}>
                                      <ul>
                                        {client.details.contact_details.emails
                                          .filter((email) => email.label === 'cliente')
                                          .map((email, index) => (
                                            <li key={index}>{email.email}</li>
                                          ))}
                                      </ul>
                                    </Collapse>
                                    <p>
                                      <strong
                                        onClick={() => setIsCobranzaEmailsOpen(!isCobranzaEmailsOpen)}
                                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                      >
                                        Correos Electrónicos de cobranza
                                        <span style={{ marginLeft: '8px' }}>
                                          {isCobranzaEmailsOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                      </strong>
                                    </p>
                                    <Collapse isOpen={isCobranzaEmailsOpen}>
                                      <ul>
                                        {client.details.contact_details.emails
                                          .filter((email) => email.label === 'cobranza')
                                          .map((email, index) => (
                                            <li key={index}>{email.email}</li>
                                          ))}
                                      </ul>
                                    </Collapse>
                                  </>
                                )}
                            </CardBody>
                          </Card>
                        </>
                      )}

                      {/* Product Details */}
                      {client.details.product_details && (
                        <>
                          <Card className="mb-4">
                            <CardBody>
                              <h3 className="mt-3">Datos de productos</h3>
                              {/* Products Sold */}
                              {client.details.product_details.products_sold &&
                                client.details.product_details.products_sold.length > 0 && (
                                  <>
                                    <p
                                      onClick={() =>
                                        setIsHistorialVentasOpen(!isHistorialVentasOpen)
                                      }
                                      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                    >
                                      <strong>
                                        Historial de ventas:
                                        <span style={{ marginLeft: '8px' }}>
                                          {isHistorialVentasOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                      </strong>
                                    </p>
                                    <Collapse isOpen={isHistorialVentasOpen}>
                                      <ul>
                                        {client.details.product_details.products_sold.map(
                                          (product, index) => (
                                            <li key={index}>
                                              {product.product_name} {'(Cantidad: '}{product.quantity}{')'}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </Collapse>
                                  </>
                                )}
                              {/* Insights */}
                              {client.details.product_details.insights &&
                                client.details.product_details.insights.length > 0 && (
                                  <>
                                    <p>
                                      <strong>Insights:</strong>
                                    </p>
                                    <ul>
                                      {client.details.product_details.insights.map(
                                        (insight, index) => (
                                          <li key={index}>{JSON.stringify(insight)}</li>
                                        )
                                      )}
                                    </ul>
                                  </>
                                )}
                            </CardBody>
                          </Card>
                        </>
                      )}

                      {/* Other Details */}
                      {client.details.other_details && (
                        <>
                          <Card className="mb-4 ">
                            <CardBody>
                              <h3 className="mt-3">Otros Detalles</h3>
                              <p>
                                <strong>Fecha de Registro:</strong>{' '}
                                {client.details.other_details.registration_date}
                              </p>
                              <p>
                                <strong>Sucursal:</strong> {client.business_branch}
                              </p>
                              <p>
                                <strong>Destinatario de factura:</strong>{' '}
                                {client.details.other_details.invoice_recipient}
                              </p>
                              <p>
                                <strong>Gestor cobranza:</strong>{' '}
                                {client.details.other_details.gestor_cobranza}
                              </p>
                              <p>
                                <strong>Comentarios:</strong>{' '}
                                {client.details.other_details.comments}
                              </p>
                              <p>
                                <strong>Lista de precios:</strong>{' '}
                                {client.details.other_details.price_list}
                              </p>
                              <p>
                                <strong>Forma de envío:</strong>{' '}
                                {client.details.other_details.shipping_method}
                              </p>
                              <p>
                                <strong>Banco por defecto:</strong>{' '}
                                {client.details.other_details.default_bank}
                              </p>
                              <p>
                                <strong>Tipo de operación comercial:</strong>{' '}
                                {client.details.other_details.type_of_commercial_operation}
                              </p>
                              {/* Agrega más campos de other_details según sea necesario */}
                            </CardBody>
                          </Card>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Right Column */}
              <div className="col-md-4">
                <div className="left-column">
                  <Card className="">
                    <CardBody>
                      <h4>Información Adicional</h4>
                      {/* Payment Terms */}
                      <p>
                        <strong>Pareto Status:</strong> {client.pareto_status}
                      </p>
                      <p>
                        <strong>Estatus de crédito:</strong> {client.payment_terms}
                      </p>

                      <p>
                        <strong>Prioridad:</strong> {client.priority}
                      </p>
                      <p>
                        <strong>Condiciones de pago:</strong> {client.payment_conditions}
                      </p>

                      {/* Result Cross Selling */}
                     
                    </CardBody>
                  </Card>

                  <Card className="mt-4">
                    <CardBody className="text-center">
                      <Button color="success" onClick={() => setIsModalOpen(true)}>
                        Venta Cruzada
                        <FaShoppingCart />
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              </div>

            </div>
          </div>
        ) : (
          <p>No se encontró el registro.</p>
        )}
      </Container>

      {/*
        MODAL PARA GENERAR DESCRIPCIÓN
      */}
      <Modal isOpen={isGenerateModalOpen} toggle={() => setIsGenerateModalOpen(false)}>
        <ModalHeader tag={'h3'} toggle={() => setIsGenerateModalOpen(false)}>
          Generar o Agregar Descripción del Cliente
        </ModalHeader>
        <ModalBody>
          {generateError && <Alert color="danger">{generateError}</Alert>}
          {generateSuccess && <Alert color="success">{generateSuccess}</Alert>}

          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="generateMode"
                value="url"
                checked={generateMode === 'url'}
                onChange={() => setGenerateMode('url')}
              />
              Generar desde URL
            </Label>
          </FormGroup>
          <FormGroup check className="mb-3">
            <Label check>
              <Input
                type="radio"
                name="generateMode"
                value="manual"
                checked={generateMode === 'manual'}
                onChange={() => setGenerateMode('manual')}
              />
              Agregar manualmente
            </Label>
          </FormGroup>

          {generateMode === 'url' ? (
            <>
              <small style={{ fontSize: '14px' }}>
                Al ingresar la URL, se recopilará información relevante del cliente desde la
                página proporcionada y se generará un resumen detallado de los datos obtenidos.
              </small>
              <Input
                type="url"
                value={clientURL}
                onChange={(e) => setClientURL(e.target.value)}
                className='mt-3'
                placeholder="Ingrese la URL del cliente"
              />
            </>
          ) : (
            <>
              <small style={{ fontSize: '14px' }}>
                Aquí puedes ingresar directamente la descripción que quieras asignar al cliente.
              </small>
              <Input
                type="textarea"
                value={manualDescription}
                onChange={(e) => setManualDescription(e.target.value)}
                className='mt-3'
                placeholder="Escribe la descripción manual..."
                rows={5}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleGenerateDescription} disabled={generateLoading}>
            {generateLoading ? <Spinner size="sm" /> : 'Guardar Descripción'}
          </Button>{' '}
          <Button
            color="secondary"
            disabled={generateLoading}
            onClick={() => {
              setIsGenerateModalOpen(false);
              setGenerateError(null);
              setGenerateSuccess(null);
              setClientURL('');
              setManualDescription('');
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/*
        MODAL PARA VENTA CRUZADA
      */}
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          setVentaCruzadaInput('');
          setModalError(null);
          setIsModalOpen(false);
        }}
      >
        <ModalHeader
          className='pb-2'
          toggle={() => {
            setVentaCruzadaInput('');
            setModalError(null);
            setIsModalOpen(false);
          }}
        >
          <h2>Añadir parámetros adicionales</h2>
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={ventaCruzadaInput}
            onChange={(e) => setVentaCruzadaInput(e.target.value)}
            placeholder="Ingrese parámetros adicionales que se tomarán en cuenta para la venta cruzada"
            maxLength={200}
          />
          {modalError && <Alert className='mt-3 p-2' color="danger">{modalError}</Alert>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleIniciarVentaCruzada} disabled={processLoading}>
            {processLoading ? <Spinner size="sm" /> : 'Iniciar venta cruzada'}
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              setVentaCruzadaInput('');
              setModalError(null);
              setIsModalOpen(false);
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DetailsVentaCruzada;