import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
  Alert,
  Spinner,
} from 'reactstrap';
import { FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/CustomTable.css';
import config from 'config/config';
import axios from 'axios';
import { PageContentContext } from 'context/PageContentContext';

const CustomTable = ({ title }) => {
  
  const [sortConfig, setSortConfig] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('naseda_token');
  const [empleadoOptions, setEmpleadoOptions] = useState([]);
  const [grupoOptions, setGrupoOptions] = useState([]);
  const [sucursalOptions, setSucursalOptions] = useState([]);
  const access_level = localStorage.getItem('naseda_access_level');
  const [client, setClient] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [filterEmpleado, setFilterEmpleado] = useState('');
  const [filterGrupo, setFilterGrupo] = useState('');
  const [filterSucursal, setFilterSucursal] = useState('');

  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const { setPageContent } = useContext(PageContentContext);

  const hiddenMeasureRefs = useRef([]);
  const [finalWidths, setFinalWidths] = useState({});
  const [initialWidths, setInitialWidths] = useState([]);

  // ---- VENTA CRUZADA ---- (Estados y funciones para el modal)
  const [isCrossSellModalOpen, setIsCrossSellModalOpen] = useState(false);
  const [crossSellInput, setCrossSellInput] = useState('');
  const [crossSellError, setCrossSellError] = useState(null);
  const [crossSellProcessLoading, setCrossSellProcessLoading] = useState(false);
  const [crossSellSnCode, setCrossSellSnCode] = useState(null);

  const handleStartVentaCruzada = (sn_code) => {
    setCrossSellError(null);
    setCrossSellInput('');
    setCrossSellSnCode(sn_code);
    setIsCrossSellModalOpen(true);
  };

  const handleConfirmCrossSell = async () => {
    if (!crossSellSnCode) return;
    setCrossSellProcessLoading(true);
    setCrossSellError(null);

    try {
      // Llamada a tu endpoint de venta cruzada
      const response = await axios.get(`${config.backendURL}/cross_sell`, {
        params: {
          id: crossSellSnCode,
          input: crossSellInput,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const clientResponse = await axios.get(`${config.backendURL}/clients/${crossSellSnCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await new Promise(resolve => setTimeout(resolve, 5000));

      setClient(clientResponse.data.client); // Asumiendo que los datos del cliente están en clientResponse.data.client
      const crossSellResults = response.data.cross_sell_items;
      console.log('Resultados de venta cruzada:', crossSellResults);
      navigate(`/admin/mis-clientes/${crossSellSnCode}/resultados-de-venta-cruzada`, {
        state: { crossSellResults, client },
      });
      // Cierra el modal
      setIsCrossSellModalOpen(false);
    } catch (error) {
      console.error('Error en venta cruzada:', error);
      setCrossSellError('Hubo un error al procesar la venta cruzada.');
    } finally {
      setCrossSellProcessLoading(false);
    }
  };
  // ---- FIN VENTA CRUZADA ----

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setShowSkeleton(true);

      // Generate initial random widths for skeleton once per fetch
      const newInitialWidths = [];
      for (let r = 0; r < itemsPerPage; r++) {
        const rowWidths = [];
        for (let c = 0; c < 4; c++) {
          const randomWidth = Math.floor(Math.random() * 51) + 40; 
          rowWidths.push(randomWidth);
        }
        newInitialWidths.push(rowWidths);
      }
      setInitialWidths(newInitialWidths);

      const params = new URLSearchParams();
      params.append('page', currentPage);
      params.append('limit', itemsPerPage);

      if (filterEmpleado) params.append('filterEmpleado', filterEmpleado);
      if (filterGrupo) params.append('filterGrupo', filterGrupo);
      if (filterSucursal) params.append('filterSucursal', filterSucursal);

      if (debouncedSearchTerm) {
        params.append('searchTerm', debouncedSearchTerm);
      }

      if (sortConfig) {
        params.append('sortKey', sortConfig.key);
        params.append('sortDirection', sortConfig.direction);
      }

      try {
        const response = await axios.get(`${config.backendURL}/clients?${params.toString()}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const result = response.data;
        setData(result.clients);
        setTotalPages(result.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    filterEmpleado,
    filterGrupo,
    filterSucursal,
    debouncedSearchTerm,
    sortConfig,
    token,
  ]);

  useEffect(() => {
    if (data) {
      const pageContentObject = {
        purpose:
          'Mis Clientes es la página donde se puede consultar los clientes ligados a usted. Puedes dar click en una empresa/cliente para consultar su información más detallada. Puedes buscar alguna empresa/cliente o filtrar por "Empleado", "Grupo" o "Sucursal".',
        clientsData: data,
      };
      const content = JSON.stringify(pageContentObject, null, 2);
      console.log(content);
      setPageContent(content);
    }
  }, [data, setPageContent]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await axios.get(`${config.backendURL}/clients/filters`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const result = response.data;
        setEmpleadoOptions(result.empleados);
        setGrupoOptions(result.grupos);
        setSucursalOptions(result.sucursales);
      } catch (error) {
        console.error('Error fetching filter options', error);
      }
    };

    fetchFilterOptions();
  }, [token]);

  const onSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const getSortIndicator = (key) => {
    if (!sortConfig) return null;
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
    }
    return null;
  };

  const PaginationComponent = () => {
    if (totalPages === 0) return null;

    const pages = [];
    pages.push(1);

    let leftSiblingIndex = Math.max(currentPage - 1, 2);
    let rightSiblingIndex = Math.min(currentPage + 1, totalPages - 1);

    const showLeftEllipsis = leftSiblingIndex > 2;
    const showRightEllipsis = rightSiblingIndex < totalPages - 1;

    if (showLeftEllipsis) {
      pages.push('...');
    } else {
      for (let i = 2; i < leftSiblingIndex; i++) {
        pages.push(i);
      }
    }

    for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
      pages.push(i);
    }

    if (showRightEllipsis) {
      pages.push('...');
    } else {
      for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
        pages.push(i);
      }
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="pagination">
        <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
          Previous
        </button>
        {pages.map((page, index) => {
          if (page === '...') {
            return (
              <span key={`ellipsis-${index}`} className="ellipsis">
                ...
              </span>
            );
          } else {
            return (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'active' : ''}
              >
                {page}
              </button>
            );
          }
        })}
        <button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    );
  };

  const handleConsultarDetalles = (sn_code) => {
    navigate(`/admin/mis-clientes/${sn_code}`);
  };

  // Measure final widths after loading
  useEffect(() => {
    if (!isLoading && data.length > 0 && showSkeleton) {
      const newWidths = {};
      hiddenMeasureRefs.current.forEach((rowRefs, rowIndex) => {
        if (!rowRefs) return;
        rowRefs.forEach((cellRef, cellIndex) => {
          if (cellRef && cellRef.current) {
            const width = cellRef.current.offsetWidth;
            newWidths[`${rowIndex}-${cellIndex}`] = width;
          }
        });
      });

      setFinalWidths(newWidths);

      // After measuring, wait a moment and hide skeleton
      setTimeout(() => {
        setShowSkeleton(false);
      }, 500);
    }
  }, [isLoading, data, showSkeleton]);

  return (
    <>
      {/* Search and Filter Controls */}
      <div className="search-filter-container">
        <div className="custom-title">
          <h1>{title}</h1>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="search-input"
          />
        </div>
        <div className="filter-container">
          <Button color="primary" onClick={toggleModal}>
            Filtrar
          </Button>
        </div>
      </div>

      {/* Filter Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Filtros</ModalHeader>
        <ModalBody>
          {access_level.includes('Administrador') && (
            <FormGroup>
              <Label for="empleadoSelect">Empleado</Label>
              <Input
                type="select"
                name="empleado"
                id="empleadoSelect"
                value={filterEmpleado || ''}
                onChange={(e) => setFilterEmpleado(e.target.value)}
              >
                <option value="">Todos</option>
                {empleadoOptions.map((empleado) => (
                  <option key={empleado} value={empleado}>
                    {empleado}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="grupoSelect">Grupo</Label>
            <Input
              type="select"
              name="grupo"
              id="grupoSelect"
              value={filterGrupo}
              onChange={(e) => setFilterGrupo(e.target.value)}
            >
              <option value="">Todos</option>
              {grupoOptions.map((grupo) => (
                <option key={grupo} value={grupo}>
                  {grupo}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="sucursalSelect">Sucursal</Label>
            <Input
              type="select"
              name="sucursal"
              id="sucursalSelect"
              value={filterSucursal}
              onChange={(e) => setFilterSucursal(e.target.value)}
            >
              <option value="">Todos</option>
              {sucursalOptions.map((sucursal) => (
                <option key={sucursal} value={sucursal}>
                  {sucursal}
                </option>
              ))}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggleModal();
              setCurrentPage(1);
            }}
          >
            Aplicar
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              toggleModal();
              setFilterEmpleado('');
              setFilterGrupo('');
              setFilterSucursal('');
              setCurrentPage(1);
            }}
          >
            Limpiar
          </Button>
        </ModalFooter>
      </Modal>

      {/* 
        // Mostrar "Sin coincidencias" con estilo si data está vacía y NO está cargando
      */}
      {!isLoading && data.length === 0 ? (
        <div className="no-results-container">
          <h3>Sin coincidencias</h3>
          <p>Prueba con otros términos de búsqueda o modifica tus filtros.</p>
        </div>
      ) : (
        <>
          {/* Table */}
          <Table className="custom-table">
            <thead>
              <tr>
                <th style={{ borderTopLeftRadius: '20px', width: '120px' }} onClick={() => onSort('sn_code')}>
                  Código SN{getSortIndicator('sn_code')}
                </th>
                <th onClick={() => onSort('business_name')}>
                  Nombre de la Empresa{getSortIndicator('business_name')}
                </th>
                <th style={{ width: '120px' }} onClick={() => onSort('pareto_status')}>
                  Grupo{getSortIndicator('pareto_status')}
                </th>
                <th style={{ width: '80px', borderTopRightRadius: '20px' }}>Venta cruzada</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: data.length > 0 ? data.length : itemsPerPage }).map((_, rowIndex) => {
                return (
                  <tr
                    key={rowIndex}
                    onClick={() => data[rowIndex] && handleConsultarDetalles(data[rowIndex].sn_code)}
                  >
                    {Array.from({ length: 4 }).map((_, cellIndex) => {
                      const baseWidth = initialWidths[rowIndex] ? initialWidths[rowIndex][cellIndex] : 60;
                      let targetWidth = baseWidth;
                      if (!isLoading && finalWidths[`${rowIndex}-${cellIndex}`]) {
                        targetWidth = finalWidths[`${rowIndex}-${cellIndex}`];
                      }

                      // Decidir qué mostrar en cada celda
                      const cellData = data[rowIndex]
                        ? cellIndex === 0
                          ? data[rowIndex].sn_code
                          : cellIndex === 1
                          ? data[rowIndex].business_name
                          : cellIndex === 2
                          ? data[rowIndex].pareto_status
                          : // Cuarta columna -> Botón
                            <Button
                              color="success"
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleStartVentaCruzada(data[rowIndex].sn_code);
                              }}
                            >
                              <FaShoppingCart />
                            </Button>
                        : '';

                      return (
                        <td
                          key={cellIndex}
                          style={{
                            cursor: data[rowIndex] && cellIndex !== 3 ? 'pointer' : 'default',
                            verticalAlign: 'middle',
                          }}
                        >
                          {/* Skeleton */}
                          <div
                            className="skeleton-rect"
                            style={{
                              width: `${targetWidth}px`,
                              opacity: isLoading || showSkeleton ? 1 : 0,
                              transition: 'opacity 0.3s ease, width 0.3s ease',
                              position: 'relative',
                            }}
                          ></div>

                          {/* Actual Text */}
                          <span
                            ref={(el) => {
                              if (!hiddenMeasureRefs.current[rowIndex]) {
                                hiddenMeasureRefs.current[rowIndex] = [];
                              }
                              hiddenMeasureRefs.current[rowIndex][cellIndex] = { current: el };
                            }}
                            style={{
                              opacity: isLoading || showSkeleton ? 0 : 1,
                              transition: 'opacity 0.3s ease',
                              position: 'absolute',
                              top: '50%',
                              left: '0',
                              transform: 'translateY(-50%)',
                              whiteSpace: 'nowrap',
                              marginLeft: 5,
                            }}
                          >
                            {cellData}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* Pagination */}
          <PaginationComponent />
        </>
      )}

      {/* ---- VENTA CRUZADA MODAL ---- */}
      <Modal
        isOpen={isCrossSellModalOpen}
        toggle={() => {
          setCrossSellInput('');
          setCrossSellError(null);
          setIsCrossSellModalOpen(!isCrossSellModalOpen);
        }}
      >
        <ModalHeader
          toggle={() => {
            setCrossSellInput('');
            setCrossSellError(null);
            setIsCrossSellModalOpen(!isCrossSellModalOpen);
          }}
        >
          Iniciar Venta Cruzada
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={crossSellInput}
            onChange={(e) => setCrossSellInput(e.target.value)}
            placeholder="Ingrese parámetros adicionales (máx. 200 caracteres)"
            maxLength={200}
          />
          {crossSellError && <Alert color="danger" className="mt-2">{crossSellError}</Alert>}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleConfirmCrossSell}
            disabled={crossSellProcessLoading}
          >
            {crossSellProcessLoading ? <Spinner size="sm" /> : 'Iniciar Venta Cruzada'}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setCrossSellInput('');
              setCrossSellError(null);
              setIsCrossSellModalOpen(false);
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      {/* ---- FIN VENTA CRUZADA MODAL ---- */}
    </>
  );
};

export default CustomTable;