// src/components/Pagination.js

import React from 'react';
import { Button } from 'reactstrap';

const Pagination = ({
    currentPage,
    totalPages,
    onPageChange,
    onNextPage,
    onPrevPage,
}) => {
    if (totalPages <= 1) return null;

    const pages = [];
    pages.push(1);

    let leftSiblingIndex = Math.max(currentPage - 1, 2);
    let rightSiblingIndex = Math.min(currentPage + 1, totalPages - 1);

    const showLeftEllipsis = leftSiblingIndex > 2;
    const showRightEllipsis = rightSiblingIndex < totalPages - 1;

    if (showLeftEllipsis) {
        pages.push("...");
    } else {
        for (let i = 2; i < leftSiblingIndex; i++) {
            pages.push(i);
        }
    }
    for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
        pages.push(i);
    }
    if (showRightEllipsis) {
        pages.push("...");
    } else {
        for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
            pages.push(i);
        }
    }
    if (totalPages > 1) {
        pages.push(totalPages);
    }

    return (
        <div className="pagination mt-2">
            <Button
                color="secondary"
                onClick={onPrevPage}
                disabled={currentPage === 1}
            >
                Anterior
            </Button>
            {pages.map((page, idx) => {
                if (page === "...") {
                    return (
                        <span key={`ellipsis-${idx}`} className="ellipsis mx-2">
                            ...
                        </span>
                    );
                } else {
                    return (
                        <Button
                            key={page}
                            color="secondary"
                            onClick={() => onPageChange(page)}
                            className={
                                currentPage === page ? "active ml-1 mr-1" : "ml-1 mr-1"
                            }
                        >
                            {page}
                        </Button>
                    );
                }
            })}
            <Button
                color="secondary"
                onClick={onNextPage}
                disabled={currentPage === totalPages}
            >
                Siguiente
            </Button>
        </div>
    );
};

export default Pagination;