import React, { useState } from 'react';
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import Header from 'components/Headers/Header';
import background from 'assets/img/brand/fondoprincipal.png';
import ReactMarkdown from 'react-markdown';
import * as XLSX from 'xlsx';

const ResultadosVentaCruzadaArticulos = () => {
  const location = useLocation();
  const { crossSellResults, item } = location.state || { crossSellResults: [] };

  // State para el modal de detalles del cliente
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  // Helper function para truncar la descripción
  const truncateDescription = (desc) => {
    if (!desc) return 'Sin descripción';
    const words = desc.trim().split(/\s+/);
    if (words.length > 30) {
      return words.slice(0, 15).join(' ') + '...';
    }
    return desc;
  };

  // Abre el modal y setea el cliente seleccionado
  const handleViewMore = (client) => {
    setSelectedClient(client);
    setModalOpen(true);
  };

  // Lógica para exportar a Excel
  const handleExportToExcel = () => {
    if (!crossSellResults || crossSellResults.length === 0) return;

    // La data real está en crossSellResults[0], según tu código (donde se hace .map).
    // Ajusta si tu estructura de datos es distinta.
    const dataToExport = crossSellResults[0].map((client) => ({
      Codigo: client.sn_code,
      Nombre: client.business_name || 'Sin Nombre',
      Descripcion: client?.details?.description || 'Sin descripción',
      AsignadoA: client.assignee || 'No asignado',
    }));

    // Crea la hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ClientesInteresados');

    // Descarga el archivo Excel
    XLSX.writeFile(workbook, `Clientes_interesados_${item?.full_name || 'Articulo'}.xlsx`);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Container
        fluid
        className="flex-grow-1"
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {/* Título + Botón Exportar a Excel */}
        <div className="mt-4 mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start">
          <h2 className="text-black text-start mb-2 mb-md-0">
            Aquí puedes encontrar clientes que les podría interesar el producto {item?.full_name || ''}.
          </h2>
          <Button color="success" onClick={handleExportToExcel}>
            Descargar
          </Button>
        </div>

        {/* Tabla de Resultados de Venta Cruzada */}
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {crossSellResults && crossSellResults.length > 0 ? (
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Asignado a</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {crossSellResults[0].map((client) => (
                  <tr key={client.sn_code}>
                    <td>{client.sn_code}</td>
                    <td>{client.business_name || 'Sin Nombre'}</td>
                    <td>
                      <ReactMarkdown>
                        {truncateDescription(client?.details?.description)}
                      </ReactMarkdown>
                    </td>
                    <td>{client.assignee || 'No asignado'}</td>
                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => handleViewMore(client)}
                      >
                        Ver más
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-center">No se encontraron resultados de venta cruzada.</p>
          )}
        </div>
      </Container>

      {/* Modal para detalles del Cliente */}
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} centered>
        <ModalHeader tag={'h3'} toggle={() => setModalOpen(!modalOpen)}>
          {selectedClient?.business_name || 'Detalles del Cliente'}
        </ModalHeader>
        <ModalBody>
          {selectedClient ? (
            <>
              <p>
                <strong>Descripción:</strong>{' '}
                <ReactMarkdown>
                  {selectedClient.details.description || 'No disponible'}
                </ReactMarkdown>
              </p>
              <p>
                <strong>Contacto:</strong>{' '}
                {selectedClient.details.contact_details.contact_name || 'No disponible'}
              </p>
              <p>
                <strong>Teléfonos:</strong>
              </p>
              <ul>
                {selectedClient.details.contact_details.phone_numbers.map((phone, index) => (
                  <li key={index}>
                    {phone.label}: {phone.number}
                  </li>
                ))}
              </ul>
              <p>
                <strong>Emails:</strong>
              </p>
              <ul>
                {selectedClient.details.contact_details.emails.map((email, index) => (
                  <li key={index}>
                    {email.label}: {email.email}
                  </li>
                ))}
              </ul>
              <p>
                <strong>Productos Vendidos Previamente:</strong>
              </p>
              <ul>
                {selectedClient.details.product_details.products_sold.map((product, index) => (
                  <li key={index}>
                    {product.product_name} - Cantidad: {product.quantity}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p>No se encontraron detalles para este cliente.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalOpen(false)}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ResultadosVentaCruzadaArticulos;