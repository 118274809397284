import React, { useState } from 'react';
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import Header from 'components/Headers/Header';
import background from 'assets/img/brand/fondoprincipal.png';
import axios from 'axios';
import 'slick-carousel/slick/slick.css'; // Ya no se requiere
import 'slick-carousel/slick/slick-theme.css'; // Ya no se requiere
import config from 'config/config';
// Biblioteca para exportar a Excel
import * as XLSX from 'xlsx';

const ResultadosVentaCruzada = () => {
  const location = useLocation();

  // Retrieve the passed cross-sell results
  const { crossSellResults, client } = location.state || { crossSellResults: [] };

  // State for the modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState(null);

  // Fetch item details
  const fetchItemDetails = async (itemCode) => {
    setLoading(true);
    setItemDetails(null);

    try {
      const response = await axios.get(`${config.backendURL}/items/${itemCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('naseda_token')}`,
        },
      });
      setItemDetails(response.data.item);
    } catch (error) {
      console.error('Error fetching item details:', error);
    } finally {
      setLoading(false);
    }
  };

  // Open modal and fetch item details
  const handleViewMore = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
    fetchItemDetails(item.item_code);
  };

  // Exportar a Excel
  const handleExportToExcel = () => {
    if (!crossSellResults || crossSellResults.length === 0) {
      return;
    }

    // Prepara la data para Excel
    const dataForExcel = crossSellResults.map(({ item_code, full_name, short_name }) => ({
      Código: item_code,
      Nombre: full_name || 'Sin nombre',
      Descripción: short_name || 'Sin descripción',
    }));

    // Crea la hoja de Excel
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Resultados');

    // Genera y descarga el archivo
    XLSX.writeFile(workbook, `Resultados_${client?.business_name || 'Cliente'}.xlsx`);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Container
        fluid
        className="flex-grow-1"
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {/* Top Left Title y Botón de Exportar */}
        <div className="mt-4 mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start">
  <h2 className="mb-2 mb-md-0">
    Aquí puedes encontrar productos que a {client?.business_name || 'el cliente'} podría interesarle.
  </h2>
  <Button color="success" onClick={handleExportToExcel}>
    Descargar
  </Button>
</div>

        {/* Tabla de Resultados de Venta Cruzada */}
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {crossSellResults && crossSellResults.length > 0 ? (
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th style={{ width: '150px' }}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {crossSellResults.map((item) => (
                  <tr key={item.item_code}>
                    <td>{item.item_code}</td>
                    <td>{item.full_name || 'Sin nombre'}</td>
                    <td>{item.short_name || 'Sin descripción'}</td>
                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => handleViewMore(item)}
                      >
                        Ver más
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-center">No se encontraron resultados de venta cruzada.</p>
          )}
        </div>
      </Container>

      {/* Modal for Item Details */}
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} centered>
        <ModalHeader tag={'h3'} toggle={() => setModalOpen(!modalOpen)}>
          {selectedItem?.full_name || 'Detalles del Artículo'}
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div className="text-center ">
              <Spinner color="primary" />
            </div>
          ) : itemDetails ? (
            <>
              <p>
                <strong>Descripción resumida:</strong>{' '}
                {itemDetails.item_details || 'No disponible'}
              </p>
              <p>
                <strong>Detalles de seguridad resumidos:</strong>{' '}
                {itemDetails.security_data || 'No disponible'}
              </p>
              {itemDetails.folder_path && (
                <p>
                  <a href={itemDetails.folder_path} target="_blank" rel="noopener noreferrer">
                    Documentos Adjuntos
                  </a>
                </p>
              )}
            </>
          ) : (
            <p>No se encontraron detalles para este artículo.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalOpen(false)}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ResultadosVentaCruzada;