import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
  Container,
} from 'reactstrap';
import { FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/CustomTable.css'; // Asegúrate de colocar el CSS aquí
import config from 'config/config';
import axios from 'axios';
import { PageContentContext } from 'context/PageContentContext';

const ArticulosTable = ({ title }) => {
  const [sortConfig, setSortConfig] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('naseda_token');
  const [empleadoOptions, setEmpleadoOptions] = useState([]);
  const [grupoOptions, setGrupoOptions] = useState([]);
  const [sucursalOptions, setSucursalOptions] = useState([]);
  const access_level = localStorage.getItem('naseda_access_level');

  const [isLoading, setIsLoading] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [filterEmpleado, setFilterEmpleado] = useState('');
  const [filterGrupo, setFilterGrupo] = useState('');
  const [filterSucursal, setFilterSucursal] = useState('');

  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const { setPageContent } = useContext(PageContentContext);

  const hiddenMeasureRefs = useRef([]);
  const [finalWidths, setFinalWidths] = useState({});
  const [initialWidths, setInitialWidths] = useState([]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setShowSkeleton(true);

      // Generate initial random widths for skeleton once per fetch
      const newInitialWidths = [];
      for (let r = 0; r < itemsPerPage; r++) {
        const rowWidths = [];
        // Tenemos 3 columnas: Código, Nombre, Nombre técnico
        for (let c = 0; c < 3; c++) {
          const randomWidth = Math.floor(Math.random() * 51) + 40;
          rowWidths.push(randomWidth);
        }
        newInitialWidths.push(rowWidths);
      }
      setInitialWidths(newInitialWidths);

      const params = new URLSearchParams();
      params.append('page', currentPage);
      params.append('limit', itemsPerPage);

      // Si volvieras a habilitar filtros, agrégalos aquí
      if (debouncedSearchTerm) {
        params.append('searchTerm', debouncedSearchTerm);
      }

      if (sortConfig) {
        params.append('sortKey', sortConfig.key);
        params.append('sortDirection', sortConfig.direction);
      }

      try {
        const response = await axios.get(
          `${config.backendURL}/items?${params.toString()}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const result = response.data;
        setData(result.items);
        setTotalPages(result.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    filterEmpleado,
    filterGrupo,
    filterSucursal,
    debouncedSearchTerm,
    sortConfig,
    token,
  ]);

  useEffect(() => {
    if (data) {
      // Ejemplo de manipulación previa de data
      const filteredData = data.map((item) => {
        const {
          item_code,
          full_name,
          short_name,
          comments,
          summarized_item_details,
          summarized_security_data,
          technical_name,
        } = item;

        const newItem = {
          item_code,
          full_name,
          short_name,
          comments,
          technical_name: technical_name || '', // Asegurar que exista
        };

        if (summarized_item_details) {
          newItem.summarized_item_details = summarized_item_details;
        }

        if (summarized_security_data) {
          newItem.summarized_security_data = summarized_security_data;
        }

        return newItem;
      });

      const pageContentObject = {
        purpose:
          'Artículos es una página donde el usuario puede consultar todos los artículos químicos. Puedes buscar algún artículo introduciendo su nombre técnico o código.',
        itemsData: filteredData,
      };

      const content = JSON.stringify(pageContentObject, null, 2);
      setPageContent(content);
    }
  }, [data, setPageContent]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await axios.get(`${config.backendURL}/clients/filters`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const result = response.data;
        setEmpleadoOptions(result.empleados);
        setGrupoOptions(result.grupos);
        setSucursalOptions(result.sucursales);
      } catch (error) {
        console.error('Error fetching filter options', error);
      }
    };

    fetchFilterOptions();
  }, [token]);

  const onSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const getSortIndicator = (key) => {
    if (!sortConfig) return null;
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
    }
    return null;
  };

  const PaginationComponent = () => {
    if (totalPages === 0) return null;

    const pages = [];
    pages.push(1);

    let leftSiblingIndex = Math.max(currentPage - 1, 2);
    let rightSiblingIndex = Math.min(currentPage + 1, totalPages - 1);

    const showLeftEllipsis = leftSiblingIndex > 2;
    const showRightEllipsis = rightSiblingIndex < totalPages - 1;

    if (showLeftEllipsis) {
      pages.push('...');
    } else {
      for (let i = 2; i < leftSiblingIndex; i++) {
        pages.push(i);
      }
    }

    for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
      pages.push(i);
    }

    if (showRightEllipsis) {
      pages.push('...');
    } else {
      for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
        pages.push(i);
      }
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="pagination">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pages.map((page, index) => {
          if (page === '...') {
            return (
              <span key={`ellipsis-${index}`} className="ellipsis">
                ...
              </span>
            );
          } else {
            return (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'active' : ''}
              >
                {page}
              </button>
            );
          }
        })}
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  const handleStartVentaCruzada = (sn_code) => {
    alert('Pendiente de implementación');
  };

  const handleConsultarDetalles = (item_code) => {
    navigate(`/admin/articulos/${item_code}`);
  };

  // Measure final widths after loading
  useEffect(() => {
    if (!isLoading && data.length > 0 && showSkeleton) {
      const newWidths = {};
      hiddenMeasureRefs.current.forEach((rowRefs, rowIndex) => {
        if (!rowRefs) return;
        rowRefs.forEach((cellRef, cellIndex) => {
          if (cellRef && cellRef.current) {
            const width = cellRef.current.offsetWidth;
            newWidths[`${rowIndex}-${cellIndex}`] = width;
          }
        });
      });

      setFinalWidths(newWidths);

      setTimeout(() => {
        setShowSkeleton(false);
      }, 500); // Pequeño retardo para la transición
    }
  }, [isLoading, data, showSkeleton]);

  return (
    <>
      {/* Search and Filter Controls */}
      <div className="search-filter-container">
        <div className="custom-title">
          <h1>{title}</h1>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="search-input"
          />
        </div>
      </div>

      {/* 
        Si deseas un modal de filtros en el futuro,
        habilítalo con toggleModal y ajusta la lógica.
      */}

      {/* 
        // Mostrar "Sin coincidencias" con estilo si data está vacía y NO está cargando
      */}
      {!isLoading && data.length === 0 ? (
        <div className="no-results-container">
          <h3>Sin coincidencias</h3>
          <p>Prueba con otros términos de búsqueda.</p>
        </div>
      ) : (
        <>
          {/* Table */}
          <Table className="custom-table">
            <thead>
              <tr>
                <th
                  style={{ borderTopLeftRadius: '20px', width: '100px' }}
                  onClick={() => onSort('item_code')}
                >
                  Código{getSortIndicator('item_code')}
                </th>
                <th onClick={() => onSort('full_name')}>
                  Nombre{getSortIndicator('full_name')}
                </th>
                <th
                  style={{ borderTopRightRadius: '20px' }}
                  onClick={() => onSort('technical_name')}
                >
                  Nombre técnico{getSortIndicator('technical_name')}
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.from({
                length: data.length > 0 ? data.length : itemsPerPage,
              }).map((_, rowIndex) => {
                // Si data.length < itemsPerPage, podría no haber datos en rowIndex
                const rowData = data[rowIndex];

                return (
                  <tr
                    key={rowIndex}
                    onClick={() =>
                      rowData && handleConsultarDetalles(rowData.item_code)
                    }
                    style={{ height: '20px' }}
                  >
                    {Array.from({ length: 3 }).map((_, cellIndex) => {
                      const baseWidth = initialWidths[rowIndex]
                        ? initialWidths[rowIndex][cellIndex]
                        : 60;
                      let targetWidth = baseWidth;
                      if (!isLoading && finalWidths[`${rowIndex}-${cellIndex}`]) {
                        targetWidth = finalWidths[`${rowIndex}-${cellIndex}`];
                      }

                      let cellData = '';
                      if (rowData) {
                        if (cellIndex === 0) cellData = rowData.item_code;
                        else if (cellIndex === 1) cellData = rowData.full_name;
                        else if (cellIndex === 2)
                          cellData = rowData.technical_name;
                      }

                      return (
                        <td
                          key={cellIndex}
                          style={{
                            position: 'relative',
                            cursor:
                              rowData && cellIndex !== 3 ? 'pointer' : 'default',
                            verticalAlign: 'middle',
                          }}
                        >
                          {/* Skeleton */}
                          <div
                            className="skeleton-rect"
                            style={{
                              width: `${targetWidth}px`,
                              opacity: isLoading || showSkeleton ? 1 : 0,
                              transition: 'opacity 0.3s ease, width 0.3s ease',
                              position: 'relative',
                              height: '20px',
                            }}
                          ></div>

                          {/* Actual Text */}
                          <span
                            ref={(el) => {
                              if (!hiddenMeasureRefs.current[rowIndex]) {
                                hiddenMeasureRefs.current[rowIndex] = [];
                              }
                              hiddenMeasureRefs.current[rowIndex][cellIndex] = {
                                current: el,
                              };
                            }}
                            style={{
                              opacity: isLoading || showSkeleton ? 0 : 1,
                              transition: 'opacity 0.3s ease',
                              position: 'absolute',
                              top: '50%',
                              left: '0',
                              transform: 'translateY(-50%)',
                              whiteSpace: 'nowrap',
                              marginLeft: 5,
                            }}
                          >
                            {cellData?.toUpperCase() || ''}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* Pagination */}
          <PaginationComponent />
        </>
      )}
    </>
  );
};

export default ArticulosTable;