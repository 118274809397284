import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
  Container,
} from 'reactstrap';
import { FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/CustomTable.css';
import config from 'config/config';
import axios from 'axios';
import { PageContentContext } from 'context/PageContentContext';

const MuestreoTable = ({ title }) => {
  const [sortConfig, setSortConfig] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('naseda_token');

  const [isLoading, setIsLoading] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [filterEmpleado, setFilterEmpleado] = useState('');
  const [filterGrupo, setFilterGrupo] = useState('');
  const [filterSucursal, setFilterSucursal] = useState('');

  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const { setPageContent } = useContext(PageContentContext);

  const hiddenMeasureRefs = useRef([]);
  const [finalWidths, setFinalWidths] = useState({});
  const [initialWidths, setInitialWidths] = useState([]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setShowSkeleton(true);

      // We have 5 columns: Orden (document_number), Cliente (client_name), Artículo (description), Precio (price), Currency (currency)
      const columnCount = 5;
      const newInitialWidths = [];
      for (let r = 0; r < itemsPerPage; r++) {
        const rowWidths = [];
        for (let c = 0; c < columnCount; c++) {
          const randomWidth = Math.floor(Math.random() * 51) + 40;
          rowWidths.push(randomWidth);
        }
        newInitialWidths.push(rowWidths);
      }
      setInitialWidths(newInitialWidths);

      const params = new URLSearchParams();
      params.append('page', currentPage);
      params.append('limit', itemsPerPage);

      if (debouncedSearchTerm) {
        params.append('searchTerm', debouncedSearchTerm);
      }

      if (sortConfig) {
        params.append('sortKey', sortConfig.key);
        params.append('sortDirection', sortConfig.direction);
      }

      try {
        const response = await axios.get(
          `${config.backendURL}/orders?${params.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = response.data;
        setData(result.orders);
        setTotalPages(result.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    filterEmpleado,
    filterGrupo,
    filterSucursal,
    debouncedSearchTerm,
    sortConfig,
    token,
  ]);

  useEffect(() => {
    if (data) {
      const filteredData = data.map((order) => {
        const {
          client_id,
          client_name,
          currency,
          description,
          item_id,
          order_id,
          order_day,
          order_month,
          order_year,
          document_number,
          price
        } = order;

        const newOrder = {
          client_id,
          client_name,
          currency,
          description,
          item_id,
          order_id,
          order_day,
          order_month,
          order_year,
          document_number,
          price
        };

        return newOrder;
      });

      const pageContentObject = {
        purpose:
          'Muestreo es una página donde el usuario puede consultar todo el historial de muestras. Puedes buscar alguna orden de una muestra realizada',
        clientsData: filteredData,
      };

      const content = JSON.stringify(pageContentObject, null, 2);
      setPageContent(content);
    }
  }, [data, setPageContent]);

  const onSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const getSortIndicator = (key) => {
    if (!sortConfig) return null;
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
    }
    return null;
  };

  const PaginationComponent = () => {
    if (totalPages === 0) return null;

    const pages = [];
    pages.push(1);

    let leftSiblingIndex = Math.max(currentPage - 1, 2);
    let rightSiblingIndex = Math.min(currentPage + 1, totalPages - 1);

    const showLeftEllipsis = leftSiblingIndex > 2;
    const showRightEllipsis = rightSiblingIndex < totalPages - 1;

    if (showLeftEllipsis) {
      pages.push('...');
    } else {
      for (let i = 2; i < leftSiblingIndex; i++) {
        pages.push(i);
      }
    }

    for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
      pages.push(i);
    }

    if (showRightEllipsis) {
      pages.push('...');
    } else {
      for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
        pages.push(i);
      }
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="pagination">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pages.map((page, index) => {
          if (page === '...') {
            return (
              <span key={`ellipsis-${index}`} className="ellipsis">
                ...
              </span>
            );
          } else {
            return (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'active' : ''}
              >
                {page}
              </button>
            );
          }
        })}
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  const handleConsultarDetalles = (item_code) => {
    navigate(`/admin/articulos/${item_code}`);
  };

  // Measure final widths after loading
  useEffect(() => {
    if (!isLoading && data.length > 0 && showSkeleton) {
      const newWidths = {};
      hiddenMeasureRefs.current.forEach((rowRefs, rowIndex) => {
        if (!rowRefs) return;
        rowRefs.forEach((cellRef, cellIndex) => {
          if (cellRef && cellRef.current) {
            const width = cellRef.current.offsetWidth;
            newWidths[`${rowIndex}-${cellIndex}`] = width;
          }
        });
      });

      setFinalWidths(newWidths);

      setTimeout(() => {
        setShowSkeleton(false);
      }, 500); // small delay to allow transition
    }
  }, [isLoading, data, showSkeleton]);

  const columnCount = 5;
  return (
    <>
      {/* Search and Filter Controls */}
      <div className="search-filter-container">
        <div className="custom-title">
          <h1>{title}</h1>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="search-input"
          />
        </div>
      </div>

      {/* Table */}
      <Table className="custom-table">
        <thead>
          <tr>
            <th style={{ borderTopLeftRadius: '20px', width: '100px'  }} onClick={() => onSort('document_number')}>
              Orden{getSortIndicator('document_number')}
            </th>
            <th onClick={() => onSort('client_name')}>
              Cliente{getSortIndicator('client_name')}
            </th>
            <th onClick={() => onSort('description')}>
              Artículo{getSortIndicator('description')}
            </th>
            <th onClick={() => onSort('price')} style={{ width: '100px' }}>
              Precio{getSortIndicator('price')}
            </th>
            <th style={{ borderTopRightRadius: '20px', width: '100px' }} onClick={() => onSort('currency')}>
              Currency{getSortIndicator('currency')}
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: data.length > 0 ? data.length : itemsPerPage }).map((_, rowIndex) => {
            const rowData = data[rowIndex];

            return (
              <tr
                key={rowIndex}
                onClick={() => rowData && handleConsultarDetalles(rowData.item_id)}
                style={{ height: '20px' }} // consistent row height
              >
                {Array.from({ length: columnCount }).map((_, cellIndex) => {
                  const baseWidth = initialWidths[rowIndex] ? initialWidths[rowIndex][cellIndex] : 60;
                  let targetWidth = baseWidth;
                  if (!isLoading && finalWidths[`${rowIndex}-${cellIndex}`]) {
                    targetWidth = finalWidths[`${rowIndex}-${cellIndex}`];
                  }

                  let cellData = '';
                  if (rowData) {
                    if (cellIndex === 0) cellData = rowData.document_number;
                    else if (cellIndex === 1) cellData = rowData.client_name;
                    else if (cellIndex === 2) cellData = rowData.description;
                    else if (cellIndex === 3) cellData = rowData.price != null ? `$${rowData.price}` : '';
                    else if (cellIndex === 4) cellData = rowData.currency;
                  }

                  return (
                    <td
                      key={cellIndex}
                      style={{
                        position: 'relative',
                        cursor: rowData && cellIndex !== 3 ? 'pointer' : 'default',
                        verticalAlign: 'middle',
                      }}
                    >
                      {/* Skeleton */}
                      <div
                        className="skeleton-rect"
                        style={{
                          width: `${targetWidth}px`,
                          opacity: (isLoading || showSkeleton) ? 1 : 0,
                          transition: 'opacity 0.3s ease, width 0.3s ease',
                          position: 'relative',
                          height: '20px',
                        }}
                      ></div>

                      {/* Actual Text */}
                      <span
                        ref={(el) => {
                          if (!hiddenMeasureRefs.current[rowIndex]) {
                            hiddenMeasureRefs.current[rowIndex] = [];
                          }
                          hiddenMeasureRefs.current[rowIndex][cellIndex] = { current: el };
                        }}
                        style={{
                          opacity: (isLoading || showSkeleton) ? 0 : 1,
                          transition: 'opacity 0.3s ease',
                          position: 'absolute',
                          top: '50%',
                          left: '0',
                          transform: 'translateY(-50%)',
                          whiteSpace: 'nowrap',
                          marginLeft: 5,
                        }}
                      >
                        {cellData ? cellData.toString().toUpperCase() : ''}
                      </span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <PaginationComponent />
    </>
  );
};

export default MuestreoTable;