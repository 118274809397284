import { useState, useEffect } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";
import { FaWhatsapp } from "react-icons/fa";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [tipoCambio, setTipoCambio] = useState(null);

  const token =
    "b5684728f68a69dda6ba0208bb9f1e430b2b6e382fbae265b31523ea0415af62";

  // Función para obtener el tipo de cambio FIX
  const obtenerTipoCambio = async () => {
    try {
      const response = await fetch(
        `https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/oportuno?token=${token}`
      );
      const data = await response.json();
      const tipo = data?.bmx?.series[0]?.datos[0]?.dato || "N/A";
      setTipoCambio(tipo);
    } catch (error) {
      console.error("Error al obtener el tipo de cambio:", error);
      setTipoCambio("Error");
    }
  };

  useEffect(() => {
    obtenerTipoCambio();
  }, []);

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      // Evita renderizar el link de Login
      if (prop.name === "Login") {
        return null;
      }

      return (
        <NavItem key={key}>
          <NavLink
        to={prop.layout + prop.path}
        tag={NavLinkRRD}
        onClick={closeCollapse}
          >
        {prop.name === "WhatsApp" ? (
          <i>
            <FaWhatsapp style={{ color: "#25D366" }} /> 
          </i>
        ) : (
          <i className={prop.icon} />
        )}
        {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
      style={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra suave
      }}
    >
      <Container fluid>
        {/* Toggler */}
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
          </NavbarBrand>
        ) : null}
        {/* User (versión mobile) */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <Media>
                  <span className="text-sm font-weight-bold">
                    {tipoCambio ? `USD: $${tipoCambio} MXN` : "Cargando..."}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bienvenido!</h6>
              </DropdownItem>
              <DropdownItem>
                <i className="ni ni-user-run" />
                <span>Cerrar sesión</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Links */}
          <Nav navbar>{createLinks(routes)}</Nav>

          {/* Sección de tipo de cambio estilizada */}
          <Row className="mt-4">
            <Col>
              <Card
                className="shadow"
                style={{
                  borderColor: "#dedede",
                  borderWidth: "1px",
                  borderRadius: "0.5rem",
                }}
              >
                <CardBody className="p-2 text-center">
                  <CardTitle
                    tag="h5"
                    className="mt-2 text-uppercase font-weight-bold"
                    style={{ color: "#5e72e4" }} // Color principal de Argon
                  >
                    Tipo de Cambio
                  </CardTitle>
                  <CardText className="mb-0">
                    {tipoCambio && tipoCambio !== "Error" && tipoCambio !== "N/A" ? (
                      <>
                        <i className="ni ni-money-coins mr-2" />
                        <span className="font-weight-bold">
                          1 USD = <span style={{ color: "#2dce89" }}>${tipoCambio}</span>{" "}
                          MXN
                        </span>
                      </>
                    ) : tipoCambio === "Error" ? (
                      <span style={{ color: "red" }}>Error al obtener el dato</span>
                    ) : (
                      "Cargando..."
                    )}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;