import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import './assets/css/VentaCruzada.css';
import './assets/css/Dashboard.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import ProtectedRoute from "components/Protected/ProtectedRoute";
import LoggedRoute from "components/Protected/LoggedRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      />
      <Route path="/auth/*" element={
        <LoggedRoute>
          <AuthLayout />
        </LoggedRoute>
      } />
      <Route path="*" element={
        <LoggedRoute>
          <AuthLayout />
        </LoggedRoute>
      } />
    </Routes>

  </BrowserRouter>
);
