import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Header from 'components/Headers/Header.js';
import background from 'assets/img/brand/fondoprincipal.png';
// import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import ReactSelect, { ActionMeta, MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import MuestreoTable from './MuestreoTable';

const animatedComponents = makeAnimated();

const Muestreo = () => {
  const access_level = localStorage.getItem('naseda_access_level');
  const token = localStorage.getItem('naseda_token');


  return (
    <>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container fluid className="flex-grow-1 d-flex flex-column">
          {/* <CustomTable
            title={
              access_level.includes('Administrador')
                ? 'Clientes'
                : 'Mis Clientes'
            }
          /> */}
          <MuestreoTable title={'Muestreo'}>

          </MuestreoTable>
          {/* Branch Selection UI */}
          {/* <div style={{ margin: '10px 0' }}>
            <h5>Selecciona los lugares:</h5>
            <ReactSelect
              isMulti
              options={branchOptions}
              value={selectedBranches}
              onChange={handleBranchChange}
              placeholder="Selecciona sucursales..."
              components={animatedComponents}
              menuPortalTarget={document.body}
              styles={customStyles}
            />
          </div>
          {/* Chart Container */}
          {/* <div id='chart' className='d-flex'></div>  */}
        </Container>
      </div>
    </>
  );
};

export default Muestreo;