import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Alert,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  ModalFooter
} from 'reactstrap';
import '../../assets/css/CustomTable.css';
import config from 'config/config';
import axios from 'axios';
import { PageContentContext } from 'context/PageContentContext';
import { FaEye, FaEdit, FaTrash, FaEllipsisV } from 'react-icons/fa';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const RolesTable = ({ title }) => {
  // State variables
  const [sortConfig, setSortConfig] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('naseda_token');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [registrationModal, setRegistrationModal] = useState(false);
  const toggleRegistrationModal = () => {
    setRegistrationModal(!registrationModal);
    // Reset form when modal closes
    if (!registrationModal) {
      resetForm();
    }
  };
  // Form fields for registration
  const [roleName, setRoleName] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [formError, setFormError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [availablePermissions, setAvailablePermissions] = useState([]);

  // Edit modal state variables
  const [editModal, setEditModal] = useState(false);
  const [editingRole, setEditingRole] = useState(null);
  const [editRoleName, setEditRoleName] = useState('');
  const [editPermissions, setEditPermissions] = useState([]);
  const [editFormError, setEditFormError] = useState('');
  const [isEditSubmitting, setIsEditSubmitting] = useState(false);

  // Delete modal state variables
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingRole, setDeletingRole] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState('');
  const [deleteError, setDeleteError] = useState('');

  // State for page content context
  const { setPageContent } = useContext(PageContentContext);

  // Debounce search term
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // Function to reset registration form
  const resetForm = () => {
    setRoleName('');
    setPermissions([]);
    setFormError('');
  };

  // Function to reset edit form
  const resetEditForm = () => {
    setEditRoleName('');
    setEditPermissions([]);
    setEditFormError('');
    setEditingRole(null);
  };

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${config.backendURL}/permissions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedPermissions = response.data.map((perm) => ({
        value: perm,
        label: perm,
      }));

      setAvailablePermissions(fetchedPermissions);
    } catch (error) {
      console.error('Error fetching permissions', error);
      // Handle error appropriately
    }
  };

  // Fetch roles data from backend
  const fetchData = async () => {
    const params = new URLSearchParams();
    params.append('page', currentPage);
    params.append('limit', itemsPerPage);

    // Include search term
    if (debouncedSearchTerm) {
      params.append('searchTerm', debouncedSearchTerm);
    }

    // Include sorting parameters
    if (sortConfig) {
      params.append('sortKey', sortConfig.key);
      params.append('sortDirection', sortConfig.direction);
    }

    try {
      const response = await axios.get(
        `${config.backendURL}/roles?${params.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      setData(result); // Data for the current page
      setTotalPages(result.totalPages); // Total number of pages
    } catch (error) {
      console.error('Error fetching roles data', error);
      // Handle error appropriately
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, debouncedSearchTerm, sortConfig, token]);

  useEffect(() => {
    fetchPermissions();
  }, [token]);

  useEffect(() => {
    if (data) {
      // Create an object for the page content
      const pageContentObject = {
        purpose:
          'Esta es la página donde puedes gestionar los roles del sistema. Puedes buscar roles y realizar acciones como ver detalles, editar o eliminar roles.',
        rolesData: data, // The actual data
      };

      // Convert the pageContentObject to a JSON string
      const content = JSON.stringify(pageContentObject, null, 2);

      setPageContent(content);
    }
  }, [data, setPageContent]);

  // Function to handle sorting
  const onSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1); // Reset to first page on sort
  };

  // Function to get the sort indicator
  const getSortIndicator = (key) => {
    if (!sortConfig) return null;
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
    }
    return null;
  };

  // Pagination Component
  const PaginationComponent = () => {
    if (totalPages === 0) return null;

    const pages = [];

    // Always show the first page
    pages.push(1);

    // Calculate the page numbers to show between ellipses
    let leftSiblingIndex = Math.max(currentPage - 1, 2);
    let rightSiblingIndex = Math.min(currentPage + 1, totalPages - 1);

    const showLeftEllipsis = leftSiblingIndex > 2;
    const showRightEllipsis = rightSiblingIndex < totalPages - 1;

    // Add left ellipsis if needed
    if (showLeftEllipsis) {
      pages.push('...');
    } else {
      // Add pages between first page and current page
      for (let i = 2; i < leftSiblingIndex; i++) {
        pages.push(i);
      }
    }

    // Add the page numbers around the current page
    for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
      pages.push(i);
    }

    // Add right ellipsis if needed
    if (showRightEllipsis) {
      pages.push('...');
    } else {
      // Add pages between current page and last page
      for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
        pages.push(i);
      }
    }

    // Always show the last page if totalPages > 1
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="pagination">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        {pages.map((page, index) => {
          if (page === '...') {
            return (
              <span key={`ellipsis-${index}`} className="ellipsis">
                ...
              </span>
            );
          } else {
            return (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'active' : ''}
              >
                {page}
              </button>
            );
          }
        })}
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Siguiente
        </button>
      </div>
    );
  };

  // Function to handle role actions
  const handleRoleAction = (role, action) => {
    switch (action) {
      case 'view':
        alert(`Ver detalles del rol con ID: ${role}`);
        break;
      case 'edit':
        setEditingRole(role);
        setEditRoleName(role.role_name);
        setEditPermissions(
          role.permissions.map((perm) => ({
            value: perm,
            label: perm,
          }))
        );
        toggleEditModal();
        break;
      case 'delete':
        setDeletingRole(role);
        toggleDeleteModal();
        break;
      default:
        break;
    }
  };

  // Handlers for permissions selection
  const handlePermissionChange = (selectedOptions) => {
    setPermissions(selectedOptions || []);
  };

  const handleEditPermissionChange = (selectedOptions) => {
    setEditPermissions(selectedOptions || []);
  };

  // Function to toggle edit modal
  const toggleEditModal = () => {
    setEditModal(!editModal);
    if (editModal) {
      resetEditForm();
    }
  };

  // Function to toggle delete modal
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
    if (deleteModal) {
      setDeletingRole(null);
    }
  };

  // Function to handle role registration
  const handleRegisterRole = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFormError('');

    // Basic validation
    if (!roleName || permissions.length === 0) {
      setFormError('Por favor, completa todos los campos obligatorios.');
      setIsSubmitting(false);
      return;
    }

    // Prepare data to send
    const newRole = {
      role_name: roleName,
      permissions: permissions.map((perm) => perm.value),
      can_be_deleted: true
    };

    try {
      const response = await axios.post(
        `${config.backendURL}/roles/register`,
        newRole,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Role created successfully
      setIsSubmitting(false);
      toggleRegistrationModal(); // Close the modal
      fetchData(); // Refresh the roles list
    } catch (error) {
      console.error('Error creating role:', error);
      setFormError('Hubo un error al crear el rol.');
      setIsSubmitting(false);
    }
  };

  // Function to handle role editing
  const handleEditRole = async (e) => {
    e.preventDefault();
    setIsEditSubmitting(true);
    setEditFormError('');

    // Basic validation
    if (!editRoleName || editPermissions.length === 0) {
      setEditFormError('Por favor, completa todos los campos obligatorios.');
      setIsEditSubmitting(false);
      return;
    }

    // Prepare data to send
    const updatedRole = {
      role_name: editRoleName,
      permissions: editPermissions.map((perm) => perm.value),
    };

    try {
      const response = await axios.put(
        `${config.backendURL}/roles/${editingRole._id}`,
        updatedRole,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Role updated successfully
      setIsEditSubmitting(false);
      toggleEditModal(); // Close the modal
      fetchData(); // Refresh the roles list
    } catch (error) {
      console.error('Error updating role:', error);
      setEditFormError('Hubo un error al actualizar el rol.');
      setIsEditSubmitting(false);
    }
  };

  // Function to handle role deletion
  const handleDeleteRole = async () => {
    if (!deletingRole) return;
    setIsEditSubmitting(true);
    try {
      await axios.delete(
        `${config.backendURL}/roles/${deletingRole._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Role deleted successfully
      setDeleteSuccess(`El rol "${deletingRole.role_name}" ha sido eliminado exitosamente.`);
      setDeleteError('');
      toggleDeleteModal();
      fetchData(); // Refresh the roles list

    } catch (error) {
      console.error('Error deleting role:', error);

      // Check if the error response status is 409 (Conflict)
      if (error.response && error.response.status === 409) {
        setDeleteError(`No se puede eliminar el rol "${deletingRole.role_name}" porque está asignado a uno o más usuarios.`);
      } else {
        setDeleteError('Hubo un error al eliminar el rol.');
      }

      setDeleteSuccess('');
      toggleDeleteModal();
    } finally{ 
      setIsEditSubmitting(false);

    }
  };


  // Reset alerts after some time
  useEffect(() => {
    let timer;
    if (deleteSuccess || deleteError) {
      timer = setTimeout(() => {
        setDeleteSuccess('');
        setDeleteError('');
      }, 5000); // Alert disappears after 5 seconds
    }

    return () => clearTimeout(timer);
  }, [deleteSuccess, deleteError]);

  return (
    <>
      {/* Search Controls */}
      <div className="search-filter-container">
        <div className="custom-title">
          <h1>{title}</h1>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Busca rol..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1); // Reset to first page on search
            }}
            className="search-input"
          />
        </div>
        <div className="upload-button-container">
          <Button color="primary" onClick={toggleRegistrationModal}>
            Nuevo Rol
          </Button>
        </div>
      </div>

      {/* Success/Error Alerts */}
      {deleteSuccess && <Alert color="success">{deleteSuccess}</Alert>}
      {deleteError && <Alert color="danger">{deleteError}</Alert>}

      {/* Table */}
      <Table className="custom-table" responsive>
        <thead>
          <tr>
            <th style={{ width: '120px', textAlign: 'center' }} onClick={() => onSort('role_name')}>
              Nombre del Rol{getSortIndicator('role_name')}
            </th>
            <th onClick={() => onSort('permissions')}>
              Permisos{getSortIndicator('permissions')}
            </th>
            <th style={{ width: '100px', textAlign: 'center' }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((role) => (
              <tr key={role._id}>
                <td>{role.role_name}</td>
                <td>{role.permissions.join(', ')}</td>
                <td className="text-center">
                  <UncontrolledDropdown direction="left">
                    <DropdownToggle
                      tag="div"
                      className="btn btn-link btn-sm"
                      style={{ cursor: 'pointer', color: 'black' }}
                    >
                      <FaEllipsisV />
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* Uncomment if you implement view functionality */}
                      {/* <DropdownItem
                        onClick={() => handleRoleAction(role, 'view')}
                      >
                        <FaEye /> Ver
                      </DropdownItem> */}
                      {role.can_be_deleted ? (
                        <>
                          <DropdownItem
                            onClick={() => handleRoleAction(role, 'edit')}
                          >
                            <FaEdit /> Editar
                          </DropdownItem>

                          <DropdownItem
                            onClick={() => handleRoleAction(role, 'delete')}
                          >
                            <FaTrash /> Eliminar
                          </DropdownItem>
                        </>
                      ) : (
                        <DropdownItem disabled>
                          No se puede modificar
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" style={{ textAlign: 'center' }}>
                Sin información
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal for Adding New Role */}
      <Modal
        isOpen={registrationModal}
        toggle={toggleRegistrationModal}
      >
        <ModalHeader tag={'h3'} toggle={toggleRegistrationModal}>
          Registrar Nuevo Rol
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleRegisterRole}>
            <FormGroup>
              <Label for="roleName">Nombre del Rol</Label>
              <Input
                type="text"
                name="roleName"
                id="roleName"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="permissions">Permisos</Label>
              <ReactSelect
                isMulti
                options={availablePermissions}
                value={permissions}
                onChange={handlePermissionChange}
                placeholder="Selecciona los permisos..."
                components={animatedComponents}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </FormGroup>
            {formError && <Alert color="danger">{formError}</Alert>}

            <div className="d-flex justify-content-end">
            <Button color="secondary" type="submit" disabled={isSubmitting} onClick={toggleRegistrationModal}>
                                Cancelar
                            </Button>
              <Button color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Registrando...' : 'Registrar'}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      {/* Modal for Editing Role */}
      <Modal
        isOpen={editModal}
        toggle={toggleEditModal}
      >
        <ModalHeader tag={'h3'} toggle={toggleEditModal}>
          Editar Rol
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleEditRole}>
            <FormGroup>
              <Label for="editRoleName">Nombre del Rol</Label>
              <Input
                type="text"
                name="editRoleName"
                id="editRoleName"
                value={editRoleName}
                onChange={(e) => setEditRoleName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="editPermissions">Permisos</Label>
              <ReactSelect
                isMulti
                options={availablePermissions}
                value={editPermissions}
                onChange={handleEditPermissionChange}
                placeholder="Selecciona los permisos..."
                components={animatedComponents}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </FormGroup>
            {editFormError && <Alert color="danger">{editFormError}</Alert>}

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit" disabled={isEditSubmitting}>
                {isEditSubmitting ? 'Actualizando...' : 'Actualizar'}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader tag={'h2'} toggle={toggleDeleteModal}>
          Confirmar Eliminación
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Estás seguro de que deseas eliminar el rol{' '}
            <strong>{deletingRole?.role_name}</strong>?
          </p>
          <p>Esta acción no se puede deshacer.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary"  disabled={isEditSubmitting} onClick={toggleDeleteModal}>
            Cancelar
          </Button>
          <Button color="danger"  disabled={isEditSubmitting} onClick={handleDeleteRole}>
          {isEditSubmitting ? 'Eliminando...' : 'Eliminar'}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Pagination */}
      <PaginationComponent />
    </>
  );
};

export default RolesTable;