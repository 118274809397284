// reactstrap components
import { Container } from "reactstrap";

const Header = () => {
  return (
    <>
      <div className="header pt-md-6" style={{ background: 'linear-gradient(to right,#142c82 ,#ec0928)' }}>
      <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
