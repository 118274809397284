// src/components/WhatsApp/WhatsApp.js

import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Input,
    ListGroup,
    ListGroupItem,
    Spinner,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Table,
    Alert,
} from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";

// core components
import Header from "components/Headers/Header.js";
import background from "assets/img/brand/fondoprincipal.png";
import axios from "axios";
import config from "config/config";
import Pagination from "components/WhatsApp/Pagination";
import Chat from "./Chat";

// Importar react-toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WhatsApp = () => {
    // ----------------------------------------------------------
    // 1) ESTADOS GENERALES
    // ----------------------------------------------------------

    const [clientLoading, setClientLoading] = useState(false);
    const [clientData, setClientData] = useState(null);

    // Para historial
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

    // Datos del Historial
    const [conversations, setConversations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Manejo de “modo”
    // "historial" o "create"
    const [viewMode, setViewMode] = useState("historial");
    const [searchClientName, setSearchClientName] = useState("");

    // Sub-modo para "create"
    const [createSubMode, setCreateSubMode] = useState("initial"); // 'initial' | 'ventaCliente' | 'ventaProducto'

    // Nuevo estado para manejar las etapas dentro de "ventaCliente"
    const [ventaClienteStage, setVentaClienteStage] = useState("selectItems"); // 'selectItems' | 'generateMessage'

    // Token
    const token = localStorage.getItem("naseda_token");

    // Items seleccionados en "Venta por Cliente"
    const [selectedItems, setSelectedItems] = useState([]);

    // Mensaje automático generado por el backend
    const [message, setMessage] = useState("");
    const [isMessageLoading, setIsMessageLoading] = useState(false);

    // Para envío de mensaje
    const [selectedPhone, setSelectedPhone] = useState("");
    const [sendStatus, setSendStatus] = useState(null); // 'success' | 'error' | null
    const [sendMessageText, setSendMessageText] = useState("");
    const [isMessageSending, setIsMessageSending] = useState(false);

    // ----------------------------------------------------------
    // 2) NUEVOS ESTADOS PARA EL CHAT
    // ----------------------------------------------------------
    // Conversación seleccionada desde el historial
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [isLoadingConversation, setIsLoadingConversation] = useState(false);
    // Mensajes del chat
    const [chatMessages, setChatMessages] = useState([]);

    // ----------------------------------------------------------
    // 3) SELECCIÓN DE ITEMS (para venta por cliente)
    // ----------------------------------------------------------
    const handleSelectItem = (itemCode) => {
        setSelectedItems((prevSelected) => {
            if (prevSelected.includes(itemCode)) {
                // Quitar
                return prevSelected.filter((code) => code !== itemCode);
            } else {
                // Agregar
                return [...prevSelected, itemCode];
            }
        });
    };

    // ----------------------------------------------------------
    // 4) USE EFFECTS: DEBOUNCE, FETCH HISTORIAL, GENERAR MENSAJE...
    // ----------------------------------------------------------
    // Debounce searchClientName para Venta por Cliente
    const [debouncedSearchClientName, setDebouncedSearchClientName] = useState("");

    // Debounce searchTerm para Historial
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);
        return () => clearTimeout(handler);
    }, [searchTerm]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchClientName(searchClientName);
        }, 500); // 500ms de debounce

        return () => {
            clearTimeout(handler);
        };
    }, [searchClientName]);

    // Fetch historial de conversaciones (cuando estamos en la vista "historial")
    useEffect(() => {
        if (viewMode !== "historial") return;

        const fetchData = async () => {
            try {
                setIsLoading(true);
                const params = new URLSearchParams();
                params.append("page", currentPage);
                params.append("limit", itemsPerPage);

                if (debouncedSearchTerm) {
                    params.append("searchTerm", debouncedSearchTerm);
                }

                const response = await axios.get(
                    `${config.backendURL}/getConversations?${params.toString()}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const result = response.data;
                setConversations(result.items || []);
                setTotalPages(result.totalPages || 1);
            } catch (error) {
                console.error("Error fetching conversations", error);
                toast.error("Error al obtener el historial de conversaciones.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [viewMode, currentPage, itemsPerPage, debouncedSearchTerm, token]);

    // Trigger buscar cliente cuando el término debounced cambia
    useEffect(() => {
        if (createSubMode === "ventaCliente" && debouncedSearchClientName) {
            handleSearchClient();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchClientName, createSubMode]);

    // Cuando seleccionamos una conversación, traemos los mensajes del chat
    useEffect(() => {
        // Llamar al endpoint de mensajes para la conversación seleccionada
        const fetchChatMessages = async () => {
            if (!selectedConversation) return;
            setIsLoadingConversation(true);
            try {
                const resp = await axios.get(
                    `${config.backendURL}/getMessages?phone_number=${selectedConversation.phone_number}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setChatMessages(resp.data.data.messages || []);
            } catch (err) {
                console.error("Error obteniendo mensajes del chat:", err);
                toast.error("Error al obtener los mensajes de la conversación.");
            } finally {
                setIsLoadingConversation(false);
            }
        };
        fetchChatMessages();
    }, [selectedConversation, token]);

    // ----------------------------------------------------------
    // 5) PAGINACIÓN
    // ----------------------------------------------------------
    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };
    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    // ---------------------------------------------------------
    // 6) VENTA POR CLIENTE
    // ---------------------------------------------------------

    // Nuevos estados para manejar los resultados de búsqueda y el cliente seleccionado
    const [clientSearchResults, setClientSearchResults] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const handleSearchClient = async () => {
        if (!debouncedSearchClientName) return;
        setClientLoading(true);
        setClientData(null);
        setSelectedClient(null); // Reiniciar el cliente seleccionado

        try {
            // Primera llamada para obtener los top 3 clientes
            const response = await axios.get(
                `${config.backendURL}/clients_by_name/${debouncedSearchClientName}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            const results = response.data.clients || [];
            setClientSearchResults(results.slice(0, 3)); // Limitar a los 3 primeros resultados
        } catch (err) {
            console.error("Error searching clients:", err);
            toast.error("Error al buscar clientes.");
            setClientSearchResults([]);
        } finally {
            setClientLoading(false);
        }
    };

    const handleSelectClientFromResults = async (clientName) => {
        setClientLoading(true);
        setSelectedClient(null);
        setClientData(null);
        console.log("Seleccionado:", clientName);
        try {
            // Segunda llamada para obtener los detalles completos del cliente seleccionado
            const response = await axios.get(
                `${config.backendURL}/client_by_name/${clientName}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setClientData(response.data);
            setSelectedClient(clientName);
            setSearchClientName(""); // Limpiar el input después de seleccionar un cliente
            toast.success(`Cliente "${clientName}" seleccionado.`);
        } catch (err) {
            console.error("Error fetching selected client details:", err);
            setClientData({ error: "No se pudo obtener los detalles del cliente seleccionado." });
            toast.error("Error al obtener los detalles del cliente seleccionado.");
        } finally {
            setClientLoading(false);
        }
    };

    const generateMessage = async () => {
        if (selectedItems.length === 0) {
            toast.error("Debe seleccionar al menos un ítem para continuar.");
            return;
        }

        if (!selectedPhone) {
            toast.error("Debe seleccionar un número de teléfono antes de continuar.");
            return;
        }

        try {
            setIsMessageLoading(true);
            const response = await axios.post(
                `${config.backendURL}/generateMessage`,
                {
                    items: selectedItems,
                    client: clientData?.client,
                    selectedPhone // Opcional: Si el backend necesita saber el número seleccionado
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setMessage(response.data.message);
            setVentaClienteStage("generateMessage");
            toast.success("Mensaje generado exitosamente.");
        } catch (error) {
            console.error("Error obteniendo el mensaje:", error);
            setMessage("Error al generar el mensaje.");
            toast.error("Error al generar el mensaje.");
        } finally {
            setIsMessageLoading(false);
        }
    };

    const renderClientCard = () => {
        if (clientLoading) {
            return (
                <div className="d-flex align-items-center mt-3">
                    <Spinner color="primary" size="sm" />
                    <span className="ml-2">Buscando cliente...</span>
                </div>
            );
        }

        // Si hay resultados de búsqueda inicial, mostrar la lista para seleccionar
        if (clientSearchResults.length > 0 && !selectedClient) {
            return (
                <Card className="mt-3 border-0" style={{ borderRadius: "0.75rem" }}>
                    <CardBody>
                        <ListGroup>
                            <Row>
                                {clientSearchResults.map((client, idx) => (
                                    <Col md="6" key={idx}>
                                        <ListGroupItem
                                            tag="button"
                                            action
                                            style={{
                                                fontSize: "0.8rem",
                                                marginTop: "1rem",
                                                borderRadius: '1rem',
                                                backgroundColor: '#f8f9fa',
                                                transition: 'background-color 0.3s',
                                            }}
                                            onClick={() => handleSelectClientFromResults(client.business_name)}
                                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e2e6ea'}
                                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f8f9fa'}
                                        >
                                            {client.business_name} (SN: {client.sn_code})
                                        </ListGroupItem>
                                    </Col>
                                ))}
                            </Row>
                        </ListGroup>
                    </CardBody>
                </Card>
            );
        }

        // Si un cliente ha sido seleccionado y se ha obtenido su data
        if (clientData && !clientData.error) {
            const { client } = clientData;
            if (!client) {
                return (
                    <Card className="mt-3 shadow border-0" style={{ borderRadius: "0.5rem" }}>
                        <CardBody>
                            <CardText>No se encontró al cliente en la respuesta.</CardText>
                        </CardBody>
                    </Card>
                );
            }

            const {
                sn_code,
                business_name,
                details = {},
                items = [],
            } = client;
            const { contact_details = {} } = details;
            const phoneNumbers = contact_details.phone_numbers || [];

            return (
                <Card className="border-0" style={{ width: "100%" }}>
                    <CardBody>
                        <CardTitle tag="h3" className="mb-3" style={{ textAlign: 'center' }}>
                            {business_name} <small>(SN: {sn_code})</small>
                        </CardTitle>

                        {ventaClienteStage === "selectItems" ? (
                            <>
                                {phoneNumbers.length > 0 ? (
                                    <div>
                                        <strong>Seleccione el teléfono para enviar el mensaje:</strong>
                                        <ul>
                                            {phoneNumbers.map((p, idx) => (
                                                <li className="mt-1" key={idx}>
                                                    <Input
                                                        type="radio"
                                                        name="selectedPhone"
                                                        value={p.number}
                                                        checked={selectedPhone === p.number}
                                                        onChange={() => setSelectedPhone(p.number)}
                                                        inline
                                                    />{" "}
                                                    {p.label} - <em>{p.number}</em>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <CardText>No hay teléfonos registrados.</CardText>
                                )}

                                {items.length === 0 ? (
                                    <CardText>No se encontraron productos para este cliente.</CardText>
                                ) : (
                                    <Table bordered responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>Sel.</th>
                                                <th>Código</th>
                                                <th>Nombre</th>
                                                <th>Especificaciones técnicas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item) => (
                                                <tr key={item.item_code}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(item.item_code)}
                                                            onChange={() => handleSelectItem(item.item_code)}
                                                        />
                                                    </td>
                                                    <td>{item.item_code}</td>
                                                    <td>{item.full_name || "Sin nombre"}</td>
                                                    <td>
                                                        {item.folder_path ? (
                                                            <a
                                                                href={item.folder_path}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="fas fa-file-pdf"></i>
                                                            </a>
                                                        ) : (
                                                            "Sin PDFs"
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}

                                {/* Botón Continuar */}
                                <div className="d-flex justify-content-end mt-3">
                                    <Button
                                        color="secondary"
                                        disabled={isMessageLoading}
                                        onClick={() => {
                                            setCreateSubMode("initial");
                                            setVentaClienteStage("selectItems");
                                            setSelectedClient(null);
                                            setSearchClientName(""); // Limpiar el input al regresar
                                            setClientSearchResults([]);
                                            setClientData(null);
                                            setSelectedItems([]);
                                            setMessage("");
                                            setSendStatus(null);
                                            setSendMessageText("");
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={generateMessage}
                                        disabled={selectedItems.length === 0 || isMessageLoading || !selectedPhone}
                                    >
                                        {isMessageLoading ? (
                                            <>
                                                <Spinner size="sm" />
                                            </>
                                        ) : (
                                            "Continuar"
                                        )}
                                    </Button>
                                </div>
                            </>
                        ) : ventaClienteStage === "generateMessage" ? (
                            <>
                                <h5 className="text-primary mt-4">Mensaje a Enviar</h5>
                                {isMessageLoading ? (
                                    <div className="d-flex align-items-center mt-2">
                                        <Spinner color="primary" size="sm" />
                                        <span className="ml-2">Generando mensaje...</span>
                                    </div>
                                ) : (
                                    <Input
                                        type="textarea"
                                        value={message}
                                        placeholder="El mensaje aparecerá aquí cuando selecciones ítems."
                                        style={{ minHeight: "300px", height: "100%", overflowY: "scroll" }}
                                    />
                                )}

                                {/* {sendStatus === "success" && (
                                    <Alert color="success" className="mt-3">
                                        {sendMessageText}
                                    </Alert>
                                )}
                                {sendStatus === "error" && (
                                    <Alert color="danger" className="mt-3">
                                        {sendMessageText}
                                    </Alert>
                                )} */}

                                <div className="d-flex justify-content-between mt-3">
                                    <Button
                                        color="secondary"
                                        onClick={() => setVentaClienteStage("selectItems")}
                                    >
                                        Regresar
                                    </Button>
                                    <Button
                                        color="success"
                                        onClick={handleSendMessage}
                                        disabled={!selectedPhone || !message || isMessageSending}
                                    >
                                        {isMessageSending ? (
                                            <>
                                                <Spinner size="sm" /> Enviando...
                                            </>
                                        ) : (
                                            "Enviar Mensaje"
                                        )}
                                    </Button>
                                </div>
                            </>
                        ) : null}
                    </CardBody>
                </Card>
            );

        // Manejar el caso de error en la búsqueda de clientes
        } else if (clientData && clientData.error && selectedClient) {
            return (
                <Card className="mt-3 shadow border-0" style={{ borderRadius: "0.5rem" }}>
                    <CardBody>
                        <CardText>{clientData.error}</CardText>
                    </CardBody>
                </Card>
            );
        }

        return null;
    };

    // ----------------------------------------------------------
    // 7) FUNCIONES ADICIONALES
    // ----------------------------------------------------------

    // Función para enviar mensaje
    const handleSendMessage = async () => {
        if (!selectedPhone || !message) {
            toast.error("Debe seleccionar un número de teléfono y generar un mensaje antes de enviar.");
            return;
        }

        setIsMessageSending(true);
        setSendStatus(null);
        setSendMessageText("");

        try {
            const response = await axios.post(
                `${config.backendURL}/sendBaileysMessage`,
                {
                    phone_destination: selectedPhone,
                    message_to_send: message,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setSendStatus("success");
                setSendMessageText(`Mensaje enviado exitosamente a ${selectedPhone}.`);
                toast.success(`Mensaje enviado exitosamente a ${selectedPhone}.`);
                // Reiniciar estados para regresar a Crear Nueva Conversación
                setCreateSubMode("initial");
                setVentaClienteStage("selectItems");
                setSelectedClient(null);
                setSearchClientName("");
                setClientSearchResults([]);
                setClientData(null);
                setSelectedItems([]);
                setMessage("");
                setSendStatus(null);
                setSendMessageText("");
            } else {
                setSendStatus("error");
                setSendMessageText("Hubo un problema al enviar el mensaje.");
                toast.error("Hubo un problema al enviar el mensaje.");
            }
        } catch (error) {
            console.error("Error al enviar el mensaje:", error);
            setSendStatus("error");
            if (error.response && error.response.data && error.response.data.error) {
                setSendMessageText(`Error: ${error.response.data.error}`);
                toast.error(`Error: ${error.response.data.error}`);
            } else {
                setSendMessageText("Error inesperado al enviar el mensaje.");
                toast.error("Error inesperado al enviar el mensaje.");
            }
        } finally {
            setIsMessageSending(false);
        }
    };

    // Autoseleccionar el teléfono si solo hay uno
    useEffect(() => {
        if (
            createSubMode === "ventaCliente" &&
            clientData &&
            clientData.client &&
            clientData.client.details &&
            clientData.client.details.contact_details &&
            clientData.client.details.contact_details.phone_numbers &&
            clientData.client.details.contact_details.phone_numbers.length === 1
        ) {
            setSelectedPhone(
                clientData.client.details.contact_details.phone_numbers[0].number
            );
        } else {
            setSelectedPhone("");
        }
    }, [createSubMode, clientData]);

    // ----------------------------------------------------------
    // 8) ANIMACIONES (Opcional)
    // ----------------------------------------------------------
    const fadeVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 },
    };

    // ----------------------------------------------------------
    // 9) FUNCIONES PARA ACTUALIZAR MENSAJES DESDE CHAT
    // ----------------------------------------------------------
    // Nueva función para actualizar mensajes desde el componente Chat
    const handleNewMessage = (message) => {
        setChatMessages((prevMessages) => [...prevMessages, message]);
    };

    // ----------------------------------------------------------
    // 10) FUNCIONALIDAD DE BORRAR CONVERSACIÓN
    // ----------------------------------------------------------

    /**
     * Función para manejar la eliminación de una conversación.
     * @param {string} phoneNumber - El número de teléfono de la conversación a eliminar.
     */
    const handleDeleteConversation = async (phoneNumber) => {
        const confirmDelete = window.confirm(
            "¿Estás seguro de que deseas borrar esta conversación? Esta acción no se puede deshacer."
        );

        if (!confirmDelete) return;

        try {
            const response = await axios.post(
                `${config.backendURL}/deleteMessages`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        phone_number: phoneNumber,
                    },
                }
            );

            if (response.status === 200) {
                toast.success("Conversación eliminada exitosamente.");
                // Actualizar el estado de conversaciones eliminando la borrada
                setConversations((prevConversations) =>
                    prevConversations.filter(
                        (conv) => conv.phone_number !== phoneNumber
                    )
                );
                // Si la conversación eliminada está actualmente seleccionada, deseleccionarla
                if (selectedConversation && selectedConversation.phone_number === phoneNumber) {
                    setSelectedConversation(null);
                    setChatMessages([]);
                }
            } else {
                toast.error("Hubo un problema al eliminar la conversación.");
            }
        } catch (error) {
            console.error("Error al eliminar la conversación:", error);
            toast.error("Error al eliminar la conversación.");
        }
    };

    // ----------------------------------------------------------
    // 11) RENDER PRINCIPAL
    // ----------------------------------------------------------
    return (
        <>
            <Header />
            <Container
                fluid
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    display: "flex",
                }}
            >
                <Row
                    style={{
                        width: "100%",
                        margin: 0,
                        marginTop: "2rem",
                        minHeight: "calc(100vh - 120px)",
                        marginBottom: "2rem",
                    }}
                >
                    {/* Columna IZQ: Historial de conversaciones */}
                    <Col
                        md="3"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "0.5rem",
                            marginLeft: "2rem",
                            marginRight: "1rem",
                            boxShadow: "0 5px 10px rgba(0,0,0,0.4)",
                            display: "flex",
                            flexDirection: "column",
                            padding: "0",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#3F2769",
                                color: "white",
                                padding: "1rem",
                                textAlign: "center",
                                borderTopLeftRadius: "0.5rem",
                                borderTopRightRadius: "0.5rem",
                            }}
                        >
                            <h4 style={{ margin: 0, color: "white" }}>
                                Historial de Conversaciones
                            </h4>
                        </div>
                        <div
                            style={{
                                padding: "1rem",
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Input
                                type="text"
                                placeholder="Buscar conversación..."
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setCurrentPage(1);
                                }}
                                style={{ marginBottom: "1rem" }}
                            />

                            {isLoading ? (
                                <div className="d-flex align-items-center">
                                    <Spinner color="primary" size="sm" />
                                    <span className="ml-2">Cargando...</span>
                                </div>
                            ) : (
                                <>
                                    <div style={{ flex: 1, overflowY: "auto" }}>
                                        {conversations.length === 0 ? (
                                            <div className="mt-3">
                                                <h6>Sin resultados</h6>
                                                <p>
                                                    Prueba con otra búsqueda o crea una
                                                    conversación.
                                                </p>
                                            </div>
                                        ) : (
                                            <ListGroup>
                                                {conversations.map((conv, idx) => {
                                                    const {
                                                        phone_number,
                                                        client_sn_code,
                                                        client_info,
                                                    } = conv;
                                                    const businessName =
                                                        client_info?.business_name || "Sin cliente";

                                                    return (
                                                        <ListGroupItem
                                                            key={idx}
                                                            onClick={() =>
                                                                setSelectedConversation(conv)
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                                fontSize: "0.8rem",
                                                            }}
                                                        >
                                                            <strong>{client_sn_code}</strong>{" "}
                                                            {businessName} {phone_number}
                                                        </ListGroupItem>
                                                    );
                                                })}
                                            </ListGroup>
                                        )}
                                    </div>
                                    {/* Paginación */}
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={setCurrentPage}
                                        onNextPage={goToNextPage}
                                        onPrevPage={goToPrevPage}
                                    />
                                </>
                            )}
                        </div>
                    </Col>

                    {/* Columna DERECHA */}
                    <Col
                        md="8"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "0.5rem",
                            marginRight: "2rem",
                            boxShadow: "0 5px 10px rgba(0,0,0,0.4)",
                            display: "flex",
                            flexDirection: "column",
                            padding: "0",
                        }}
                    >
                        {/* Encabezado */}
                        <div
                            style={{
                                backgroundColor: "#3F2769",
                                color: "white",
                                padding: "1rem",
                                borderTopLeftRadius: "0.5rem",
                                borderTopRightRadius: "0.5rem",
                                textAlign: "center",
                            }}
                        >
                            {selectedConversation ? (
                                <h4 style={{ margin: 0, color: "white" }}>
                                    {selectedConversation.client_info?.business_name} -{" "}
                                    {selectedConversation.phone_number}
                                </h4>
                            ) : viewMode === "create" ? (
                                <h4 style={{ margin: 0, color: "white" }}>
                                    Tipo de Venta
                                </h4>
                            ) : (
                                <h4 style={{ margin: 0, color: "white" }}>
                                    Nueva Conversación
                                </h4>
                            )}
                        </div>

                        {/* Contenido */}
                        <div style={{ padding: "1rem", flex: 1 }}>
                            {selectedConversation ? (
                                <>
                                    {/* Resumen de la Conversación */}
                                    <h5 style={{ color: "red", marginTop: '1px' }}>
                                        RESUMEN DE LA CONVERSACIÓN
                                    </h5>
                                    <p>
                                        Aquí podrías poner datos de la conversación,
                                        información del cliente, etc.
                                    </p>

                                    {/* Componente Chat */}
                                    <Chat
                                        selectedConversation={selectedConversation}
                                        chatMessages={chatMessages}
                                        token={token}
                                        onNewMessage={handleNewMessage}
                                        isLoadingConversation={isLoadingConversation}
                                    />

                                    {/* Botones para Regresar o Borrar Conversación */}
                                    <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between" }}>
                                        <Button
                                            color="secondary"
                                            onClick={() => {
                                                // Regresar al historial
                                                setSelectedConversation(null);
                                            }}
                                        >
                                            Regresar
                                        </Button>
                                        {!isLoadingConversation && (
                                            <Button
                                                color="danger"
                                                onClick={() => handleDeleteConversation(selectedConversation.phone_number)}
                                            >
                                                <i className="fas fa-trash-alt"></i> Borrar Conversación
                                            </Button>
                                        )}
                                    </div>
                                </>
                            ) : viewMode === "create" ? (
                                createSubMode === "initial" ? (
                                    <div className="d-flex flex-column align-items-center">
                                        <div
                                            className="mt-4"
                                            style={{ display: "flex", gap: "1rem" }}
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => setCreateSubMode("ventaProducto")}
                                            >
                                                Venta por Producto
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    setCreateSubMode("ventaCliente");
                                                    setVentaClienteStage("selectItems"); // Resetear etapa al entrar
                                                }}
                                            >
                                                Venta por Cliente
                                            </Button>
                                        </div>
                                        <Button
                                            color="secondary"
                                            className="mt-4"
                                            style={{ textAlign: 'left' }}
                                            onClick={() => {
                                                setViewMode("historial");
                                                setCreateSubMode("initial");
                                            }}
                                        >
                                            Regresar
                                        </Button>
                                    </div>
                                ) : createSubMode === "ventaCliente" ? (
                                    <div className="d-flex flex-column align-items-center">
                                        {/* Solo mostrar el Input si no hay un cliente seleccionado */}
                                        {!selectedClient && (
                                            <div style={{ width: "100%", maxWidth: "500px" }}>
                                                <p>
                                                    Se regresarán los clientes que más se asemejen al nombre ingresado.
                                                    Seleccione el cliente deseado de la lista.
                                                </p>
                                                <Input
                                                    type="text"
                                                    placeholder="Escribe el nombre del cliente..."
                                                    value={searchClientName}
                                                    onChange={(e) => setSearchClientName(e.target.value)}
                                                />
                                                {/* Eliminar el botón "Buscar" ya que la búsqueda es automática */}
                                            </div>
                                        )}

                                        {renderClientCard()}

                                        {!selectedClient && (
                                            <Button
                                                color="secondary"
                                                className="mt-4"
                                                onClick={() => {
                                                    setCreateSubMode("initial");
                                                    setVentaClienteStage("selectItems");
                                                    setSelectedClient(null);
                                                    setSearchClientName(""); // Limpiar el input al regresar
                                                    setClientSearchResults([]);
                                                    setClientData(null);
                                                    setSelectedItems([]);
                                                    setMessage("");
                                                    setSendStatus(null);
                                                    setSendMessageText("");
                                                }}
                                            >
                                                Cancelar
                                            </Button>
                                        )}
                                    </div>
                                ) : createSubMode === "ventaProducto" ? (
                                    <div className="d-flex flex-column align-items-center">
                                        <h3>Venta por Producto</h3>

                                        {/* Aquí puedes implementar la funcionalidad de Venta por Producto */}

                                        <p className="mt-3">
                                            Funcionalidad de Venta por Producto en desarrollo.
                                        </p>

                                        <Button
                                            color="secondary"
                                            className="mt-4"
                                            onClick={() => setCreateSubMode("initial")}
                                        >
                                            Regresar
                                        </Button>
                                    </div>
                                ) : null
                            ) : (
                                <div className="text-center">
                                    <Button
                                        color="danger"
                                        onClick={() => setViewMode("create")}
                                    >
                                        Crear Nueva Conversación
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* Componente de Notificaciones */}
            <ToastContainer />
        </>
    );

};

export default WhatsApp;