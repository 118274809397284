import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Spinner } from "reactstrap";
import axios from "axios";
import config from "config/config";

const Chat = ({
    selectedConversation,
    chatMessages,
    token,
    onNewMessage, // Función para actualizar los mensajes en el componente padre
    isLoadingConversation
}) => {
    const [newMessage, setNewMessage] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [sendStatus, setSendStatus] = useState(null); // 'success' | 'error' | null
    const [sendMessageText, setSendMessageText] = useState("");

    const messagesEndRef = useRef(null);

    // Scroll automático hacia el final de los mensajes
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [chatMessages]);

    // Temporizador para ocultar el mensaje de error después de 5 segundos
    useEffect(() => {
        if (sendStatus === "error") {
            const timer = setTimeout(() => {
                setSendStatus(null);
                setSendMessageText("");
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [sendStatus]);

    const handleSend = async () => {
        if (!newMessage.trim()) return;

        setIsSending(true);
        setSendStatus(null);
        setSendMessageText("");

        try {
            const response = await axios.post(
                `${config.backendURL}/sendBaileysMessage`,
                {
                    phone_destination: selectedConversation.phone_number,
                    message_to_send: newMessage,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setSendStatus("success");
                setSendMessageText("Mensaje enviado exitosamente.");
                // Actualizar los mensajes en el componente padre
                onNewMessage({
                    type: "sent",
                    content: newMessage,
                });
                setNewMessage("");
            } else {
                setSendStatus("error");
                setSendMessageText("Hubo un problema al enviar el mensaje.");
            }
        } catch (error) {
            console.error("Error al enviar el mensaje:", error);
            setSendStatus("error");
            if (error.response && error.response.data && error.response.data.error) {
                setSendMessageText(`Error: ${error.response.data.error}`);
            } else {
                setSendMessageText("Error inesperado al enviar el mensaje.");
            }
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div>
            {isLoadingConversation ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 380px)",
                    backgroundColor: "#F0ECE3"
                }}>
                    <Spinner color="primary" />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            backgroundColor: "#F0ECE3",
                            padding: "1rem",
                            marginBottom: "1rem",
                            borderRadius: "0.5rem",
                            height: "calc(100vh - 400px)", // Ajusta este valor según sea necesario
                            overflowY: "auto",
                        }}
                    >
                        {chatMessages.map((msg, idx) => {
                            const isClient = msg.type === "client";
                            return (
                                <div
                                    key={idx}
                                    style={{
                                        textAlign: isClient ? "left" : "right",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "inline-block",
                                            backgroundColor: isClient ? "#C1F0C1" : "#FFF",
                                            borderRadius: "0.75rem",
                                            padding: "0.5rem 1rem",
                                            maxWidth: "70%",
                                            textAlign: "left",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        {msg.content}
                                    </div>
                                </div>
                            );
                        })}
                        <div ref={messagesEndRef} />
                    </div>

                    {/* Campo de input para enviar mensaje al chat */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Input
                            type="text"
                            placeholder="Escribe tu mensaje..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleSend();
                                }
                            }}
                            style={{ marginRight: "0.5rem", flex: 1 }}
                        />
                        <Button color="success" onClick={handleSend} disabled={isSending}>
                            {isSending ? (
                                <>
                                    <Spinner size="sm" /> Enviando...
                                </>
                            ) : (
                                <i className="fas fa-paper-plane"></i>
                            )}
                        </Button>
                    </div>

                    {/* Mensajes de estado */}
                    {/* {sendStatus === "success" && (
                        <div className="alert alert-success mt-3" role="alert">
                            {sendMessageText}
                        </div>
                    )} */}
                    {sendStatus === "error" && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {sendMessageText}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Chat;