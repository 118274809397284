import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
  } from "reactstrap";
  
  import { useState, useEffect } from 'react';
  import axios from "axios";
  import config from "config/config";
  import loadingGif from "../../assets/img/loading/loading.gif";
  
  const PasswordRecovery = ({ onBack }) => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [timer, setTimer] = useState(30);
    const [canResend, setCanResend] = useState(false);
  
    useEffect(() => {
      let interval;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer((prev) => prev - 1);
        }, 1000);
      } else {
        setCanResend(true);
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [timer]);
  
    const handleSendCode = async () => {
      setIsLoading(true);
      setError('');
      try {
        await axios.post(`${config.backendURL}/send_recovery_code`, { email });
        setSuccess('Código enviado a tu correo.');
        setStep(2);
        setTimer(30);
        setCanResend(false);
      } catch (err) {
        setError('Error al enviar el código, verifica el correo ingresado.');
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleVerifyCode = async () => {
      setIsLoading(true);
      setError('');
      try {
        await axios.post(`${config.backendURL}/verify_recovery_code`, { email, code });
        setSuccess('Código verificado correctamente. Redirigiendo...');
        setTimeout(() => {
          window.location.href = '/create-password';
        }, 1500);
      } catch (err) {
        setError('Código incorrecto, verifícalo.');
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
            {error && <div className="alert alert-danger mt-3">{error}</div>}
            {success && <div className="alert alert-success mt-3">{success}</div>}
              <div className="text-center text-muted mb-4">
                <h1 className="mt-5">
                  {step === 1 ? 'Recupera tu contraseña' : 'Verifica tu código'}
                </h1>
              </div>
              <Form>
                {step === 1 && (
                  <>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Correo electrónico"
                          type="email"
                          autoComplete="off"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={handleSendCode}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <img
                            className="loading"
                            src={loadingGif}
                            alt="Cargando..."
                            style={{ height: '20px' }}
                          />
                        ) : (
                          'Recuperar'
                        )}
                      </Button>
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Código de verificación"
                          type="text"
                          maxLength="6"
                          autoComplete="off"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={handleVerifyCode}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <img
                            className="loading"
                            src={loadingGif}
                            alt="Cargando..."
                            style={{ height: '20px' }}
                          />
                        ) : (
                          'Verificar Código'
                        )}
                      </Button>
                    </div>
                    <div className="text-center">
                      <Button
                        className="btn-link"
                        color="secondary"
                        type="button"
                        disabled={!canResend}
                        onClick={() => {
                          handleSendCode();
                          setTimer(30);
                          setCanResend(false);
                        }}
                      >
                        {canResend ? 'Reenviar Código' : `Reenviar en ${timer}s`}
                      </Button>
                    </div>
                  </>
                )}
                
              </Form>
              <div className="text-center">
                <Button
                  className="btn-link"
                  color="secondary"
                  type="button"
                  onClick={() => {onBack()
                    setEmail('');
                  }}
                >
                  Regresar
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  };
  
  export default PasswordRecovery;