import Login from "views/examples/Login.jsx";
import VentaCruzada from "views/VentaCruzada/VentaCruzada";
import Muestreo from "views/Muestreo/Muestreo";
import Copilot from "views/Copilot/Copilot";
import NasedaLabs from "views/NasedaLabs/NasedaLabs";
import BusinessIntelligence from "views/BusinessIntelligence/BusinessIntelligence";
import DetailsVentaCruzada from "views/VentaCruzada/DetailsVentaCruzada";
import Settings from "views/Settings/Settings";
import AdminUsers from "views/UsuariosRolesPermisos/AdminUsers";
import Roles from "views/UsuariosRolesPermisos/Roles";
import Articulos from "views/Articulos/Articulos";
import ConfirmAccount from "views/ConfirmAccount/ConfirmAccount";
import ResultadosVentaCruzada from "views/VentaCruzada/ResultadosVentaCruzada";
import DetailArticulo from "views/Articulos/DetailArticulo";
import ResultadosVentaCruzadaArticulos from "views/Articulos/ResultadosVentaCruzadaArticulos";
import WhatsApp from "views/WhatsApp/WhatsApp";



var routes = [
  {
    path: "/mis-clientes",
    name: "Mis Clientes",
    icon: "ni ni-money-coins text-primary",
    component: <VentaCruzada />,
    layout: "/admin", hidden: false,
    permission: 'Clientes'


  },
  {
    path: "/articulos",
    name: "Artículos",
    icon: "ni ni-bag-17 text-primary",
    component: <Articulos />,
    layout: "/admin", hidden: false,
    permission: 'Artículos'


  },
  {
    path: "/muestreo",
    name: "Muestreo",
    icon: "ni ni-chart-pie-35 text-blue",
    component: <Muestreo />,
    layout: "/admin", hidden: false,
    permission: 'Muestreo',
    disabled: true


  },
  {
    path: "/copilot",
    name: "Copilot",
    icon: "ni ni-chat-round text-orange",
    component: <Copilot />,
    layout: "/admin", hidden: false,     
    permission: 'Copilot'


  },
  // {
  //   path: "/naseda-labs",
  //   name: "NASEDA Labs",
  //   icon: "ni ni-ruler-pencil text-yellow",
  //   component: <NasedaLabs />,
  //   layout: "/admin", hidden: false,
  //   permission: 'Naseda Labs',
  //   disabled: true


  // },
  {
    path: "/business-intelligence",
    name: "Business Intelligence",
    icon: "ni ni-building text-info",
    component: <BusinessIntelligence />,
    layout: "/admin", 
    hidden: false,
    permission: 'Business Intelligence'


  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-building text-info",
    component: <Login />,
    layout: "/auth",

  },
  {
    path: "/mis-clientes/:id", 
    name: "Venta Cruzada",
    icon: "ni ni-cart text-success",
    component: <DetailsVentaCruzada />,
    layout: "/admin",
    hidden: true,
    permission: 'Venta Cruzada'

  },
  {
    path: "/articulos/:id", 
    name: "Artículo",
    icon: "ni ni-cart text-success",
    component: <DetailArticulo />,
    layout: "/admin",
    hidden: true,
    permission: 'Artículos'

  },
  {
    path: "/mis-clientes/:id/resultados-de-venta-cruzada", 
    name: "Venta Cruzada",
    icon: "ni ni-cart text-success",
    component: <ResultadosVentaCruzada />,
    layout: "/admin",
    hidden: true,
    permission: 'Resultados Venta Cruzada'
  },
  {
    path: "/configuracion", 
    name: "Configuración",
    icon: "ni ni-cart text-success",
    component: <Settings />,
    layout: "/admin",
    hidden: true,
    permission: 'Configuración'

  },
  {
    path: "/configuracion/administracion-de-usuarios", 
    name: "Administración de Usuarios",
    icon: "ni ni-cart text-success",
    component: <AdminUsers/>,
    layout: "/admin",
    hidden: true,
    permission: 'Usuarios'

  },
  {
    path: "/configuracion/roles-y-permisos", 
    name: "Roles y permisos",
    icon: "ni ni-cart text-success",
    component: <Roles/>,
    layout: "/admin",
    hidden: true,
    permission: 'Roles'
  },
  {
    path: "/confirmar_cuenta",
    name: "Login",
    icon: "ni ni-building text-info",
    component: <ConfirmAccount />,
    layout: "/auth",

  },
  {
    path: "/articulos/:id/resultados-de-venta-cruzada", 
    name: "Venta Cruzada",
    icon: "ni ni-cart text-success",
    component: <ResultadosVentaCruzadaArticulos />,
    layout: "/admin",
    hidden: true,
    permission: 'Resultados Venta Cruzada Artículos'
  },
  {
    path: "/whatsapp",
    name: "WhatsApp",
    icon: "fa fa-whatsapp text-success",
    component: <WhatsApp />,
    layout: "/admin", 
    hidden: false,
    permission: 'Business Intelligence'


  },
];
export default routes;
